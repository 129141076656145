import * as React from 'react';

import CustomerForm from 'src/forms/CustomerForm';
import PartName from 'src/components/common/PartName';
import RockettaModal from 'src/components/common/modals/RockettaModal';
import Notiflix from 'notiflix';

import { CommandBarButton, ProgressIndicator } from '@fluentui/react';
import { addCustomer, getAllCustomers, ICustomerPostRequest } from 'src/api/customer';
import { ICustomerDto, Language } from 'src/common/dto';
import { addIcon } from 'src/common/icons';
import { AxiosError } from 'axios';
import LoaderModal from 'src/components/common/modals/LoaderModal';
import { setDocumentTitle } from 'src/common/functions';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ColumnFilterItem } from 'antd/es/table/interface';
import Customer from 'src/components/common/entities/Customer';


interface CustomerPageProps {

}

interface CustomersPageState {
    customers: ICustomerDto[];
    isOpenAddModel: boolean;
    loaded: boolean;
    loadingModalOpen: boolean;
    columns: ColumnsType<ICustomerDto>
}

class CustomersPage extends React.Component<CustomerPageProps, CustomersPageState> {
    constructor(props: CustomerPageProps) {
        super(props)
        this.state = {
            customers: [],
            isOpenAddModel: false,
            loaded: false,
            loadingModalOpen: false,
            columns: []
        }
    }

    componentDidMount = (): void => {
        setDocumentTitle('Customers')
        const response = getAllCustomers()

        response.then((response) => {
            if (response.status === 200) {
                const customers: ICustomerDto[] = response.data;
                const columns: ColumnsType<ICustomerDto> = [
                    {
                        title: 'Name',
                        dataIndex: 'name',
                        key: 'name',
                        render: (text, record) => <Customer customer={record} />,
                        sorter: (a, b) => a.name.localeCompare(b.name),
                        filters: customers
                            .map(r => { return { text: r.name, value: r.name } })
                            .reduce((accum: ColumnFilterItem[], val: ColumnFilterItem) => accum.some(v => v.text === val.text) ? accum : [...accum, val], []),
                        filterSearch: true,
                        onFilter: (value, record) => {
                            return record.name === value
                        },
                    },
                ]
                this.setState({ customers, loaded: true, columns })
            }
        })
        response.catch((error: AxiosError) => {
            Notiflix.Notify.failure(error.message);
        })
    };

    changeAddModalState = (): void => {
        this.setState({ isOpenAddModel: !this.state.isOpenAddModel })
    }

    addCustomer = (customer: ICustomerPostRequest, language: Language): void => {
        this.setState({ loadingModalOpen: true })
        const response = addCustomer(customer, language);

        response.then(r => {
            if (r.status === 201) {
                const dbCustomer = r.data
                const customers = [...this.state.customers]
                customers.unshift(dbCustomer);
                this.setState({ customers, isOpenAddModel: false });
                Notiflix.Notify.success('Customer successfully added');
            }
        })
        response.catch((error: AxiosError) => {
            if (error.response?.status === 409)
                Notiflix.Notify.warning('Customer with the same name already exist');
            else
                Notiflix.Notify.failure(`${error.response?.data}`)
        })

        this.setState({ loadingModalOpen: false })
    }

    render(): React.ReactElement<CustomersPage> {
        return this.state.loaded ? (
            <div>
                <div className='p-5 flex flex-col gap-2'>
                    <PartName name='Customers' />
                    <div>
                        <CommandBarButton
                            iconProps={addIcon}
                            text="New customer"
                            className='h-11'
                            onClick={this.changeAddModalState}
                        />
                    </div>

                    {this.state.customers &&
                        <Table
                            rowKey={(record) => record.id}
                            columns={this.state.columns}
                            dataSource={this.state.customers}
                            pagination={false}
                            showSorterTooltip={false}
                            size='middle'
                        />
                    }
                </div>

                <RockettaModal
                    isOpen={this.state.isOpenAddModel}
                    closeModal={this.changeAddModalState}
                    name='New customer'
                >
                    <CustomerForm
                        addMode={{ addCustomer: this.addCustomer }}
                        buttonText='Add'
                    />
                </RockettaModal>

                <LoaderModal isOpen={this.state.loadingModalOpen} label='Customer is creating, please wait' />
            </div>
        ) : <ProgressIndicator className='mt-m-056' />
    }
}

export default CustomersPage;