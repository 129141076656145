import axios, { AxiosResponse } from "axios";
import { IDocBasePostDto } from "src/common/dto";

export interface IQuotationPostDto extends IDocBasePostDto {
}

export const createQuotation = async (quotation: IQuotationPostDto): Promise<AxiosResponse> => {
    const response = await axios.post(`quotation`, quotation)
    return response;
}

export const getQuotation = async (id: number): Promise<AxiosResponse> => {
    const response = await axios.get(`quotation/${id}`)
    return response;
}

export const getAllQuotations = async (): Promise<AxiosResponse> => {
    const response = await axios.get(`quotation`)
    return response;
}

export const downloadQuotation = async (id: number): Promise<AxiosResponse> => {
    const response = await axios.get(`quotation/download/${id}`, { responseType: "blob" })
    return response;
}