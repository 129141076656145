import { IModulePutDto } from "src/api/module";

export interface IContactPersonDto {
    id: number;
    firstName: string;
    lastName: string | null;
    email: string | null;
    phone: string | null;
    contactDataId: number;
}

export interface IModuleDto {
    id: number;
    quantity: number;
    description: string;
    isOptional: boolean;
    timeInterval: string;
    price: number;
    type: string;
    sortId: number;
    projectId: number
}

export interface IEMailSetting {
    to: string;
    cc: string;
    subject: string;
    text: string;
}

export interface IProjectDto {
    id: number;
    name: string;
    createdDate: Date;
    tax: number;
    status: string;
    customerOrderNo: string;
    defaultPricePerDay: number;
    defaultQuotationTextAbove: string | null;
    defaultInvoiceTextAbove: string | null;
    defaultQuotationTextBelow: string | null;
    defaultInvoiceTextBelow: string | null;
    customerDefaultQuotationTextAbove: string | null;
    customerDefaultInvoiceTextAbove: string | null;
    customerDefaultQuotationTextBelow: string | null;
    customerDefaultInvoiceTextBelow: string | null;
    language: Language;
    modules: IModuleDto[];
    quotations: IQuotationDto[];
    invoices: IInvoiceDto[];
    customerId: number;
    customerName: string;
}

export interface ICustomerDto {
    id: number;
    name: string;
    tax: number;
    contractor: string | null;
    orderNo: string;
    address: IAddressDto | null;
    language: Language;
    to: string;
    cc: string;
    text: string;
    subject: string;
    defaultPricePerDay: number;
    contactPeople: IContactPersonDto[]
    createdDate: Date;
    projects: IProjectDto[];
}

export interface IAddressDto {
    id: number;
    streetHouse: string;
    department: string | null;
    zipCode: string;
    country: string | null;
    city: string | null;
    customerId: number;
}

export interface IDocBaseDto {
    id: number;
    number: string;
    customerName: string;
    customerId: number;
    projectName: string;
    projectId: number;
    createdDate: Date;
    createdBy: IUserDto;
    price: number;
}

export interface IDocBasePostDto {
    textAbove: string;
    textBelow: string;
    modules: IModulePutDto[];
    projectId: number;
}

export interface IQuotationDto extends IDocBaseDto {
}

export interface IInvoiceDto extends IDocBaseDto {
    type: string;
    lastPaymentDate: Date;
    payments: IPaymentDto[];
}

export enum Language {
    English,
    German
}

export enum DocType {
    Invoice,
    Quotation
}

export enum InvoiceType {
    Invoice,
    Partial,
    PartialModules,
    Final
}

export enum ModuleType {
    Testing,
    Management,
    Module,
    PercentageModule
}

export interface IPaymentDto {
    id: number;
    date: Date;
    price: number;
    invoiceId: number;
}

export interface IUser {
    id: string;
    name: string;
    eMail: string;
}

export interface IUserDto {
    id: string;
    name: string;
}