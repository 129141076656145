import axios, { AxiosResponse } from "axios";

export interface IAddressPostDto {
    streetHouse: string;
    department: string | null;
    zipCode: string;
    country: string | null;
    city: string | null;
    customerId: number
}

export interface IAddressPutDto {
    streetHouse: string;
    department: string | null;
    zipCode: string;
    country: string | null;
    city: string | null;
}

export const createAddress = async (address: IAddressPostDto): Promise<AxiosResponse> => {
    const response = await axios.post("address", address)
    return response;
}

export const updateAddress = async (address: IAddressPutDto, id: number): Promise<AxiosResponse> => {
    const response = await axios.put(`address/${id}`, address)
    return response;
}

export const deleteAddress = async (id: number): Promise<AxiosResponse> => {
    const response = await axios.delete(`address/${id}`)
    return response;
}