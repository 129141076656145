import * as React from 'react';

import { Persona } from '@fluentui/react';
import { Link } from 'react-router-dom';
import { ICustomerDto } from 'src/common/dto';

interface ICustomerProps {
    customer: ICustomerDto;
    className?: string;
}

interface ICustomerState {

}

class Customer extends React.Component<ICustomerProps, ICustomerState> {
    constructor(props: ICustomerProps) {
        super(props);
    }

    render(): React.ReactElement<Customer> {
        const url = '/customers/' + this.props.customer.id;

        return (
            <Link to={url}>
                <Persona
                    className='coin-container'
                    text={this.props.customer.name}
                    secondaryText={this.props.customer.contractor ? this.props.customer.contractor : undefined}
                />
            </Link>
        );
    }
}

export default Customer;