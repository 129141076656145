import * as React from 'react';

import { BaseButton } from '@fluentui/react';
import { Editor } from '@tiptap/react';

interface MenuBarProps {
    editor: Editor | null;
}

interface MenuBarState {

}

class MenuBar extends React.Component<MenuBarProps, MenuBarState> {

    render(): React.ReactElement<MenuBar> {
        return (
            <div className='text-center pb-2 border-b text-gray-600'>
                <BaseButton
                    text='Bold'
                    onClick={() => this.props.editor?.chain().focus().toggleBold().run()}
                    className='mr-5'
                />
                <BaseButton
                    text='Italic'
                    onClick={() => this.props.editor?.chain().focus().toggleItalic().run()}
                    className='mr-5'
                />
                <BaseButton
                    text='H1'
                    onClick={() => this.props.editor?.chain().focus().toggleHeading({ level: 1 }).run()}
                    className='mr-5'
                />
                <BaseButton
                    text='H2'
                    onClick={() => this.props.editor?.chain().focus().toggleHeading({ level: 2 }).run()}
                    className='mr-5'
                />
                <BaseButton
                    text='OL'
                    onClick={() => this.props.editor?.chain().focus().toggleBulletList().run()}
                    className='mr-5'
                />
                <BaseButton
                    text='UL'
                    onClick={() => this.props.editor?.chain().focus().toggleOrderedList().run()}
                    className='mr-5'
                />
                <BaseButton
                    text='Left'
                    onClick={() => this.props.editor?.chain().focus().setTextAlign("left").run()}
                    className='mr-5'
                />
                <BaseButton
                    text='Center'
                    onClick={() => this.props.editor?.chain().focus().setTextAlign("center").run()}
                    className='mr-5'
                />
                <BaseButton
                    text='Right'
                    onClick={() => this.props.editor?.chain().focus().setTextAlign("right").run()}
                    className='mr-5'
                />
            </div>
        );
    }
}

export default MenuBar;