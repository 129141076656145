import axios, { AxiosResponse } from "axios";

export interface IModulePostDto{
    quantity: number;
    description: string;
    price: number;
    isOptional: boolean;
    timeInterval: string;
    type: string;
    sortId: number;
    projectId: number;
}

export interface IModulePutDto {
    quantity: number;
    description: string;
    price: number;
    isOptional: boolean;
    timeInterval: string;
    sortId: number;
    type: string;
}

export interface IModuleSortPatchDto {
    id: number;
    sortId: number
}

export const addModule = async (module: IModulePostDto): Promise<AxiosResponse> => {
    const response = await axios.post(`module`, module)
    return response;
}

export const updateModule = async (id: number, module: IModulePutDto): Promise<AxiosResponse> => {
    const response = await axios.put(`module/${id}`, module)
    return response;
}

export const updateModules = async (module: IModuleSortPatchDto[]): Promise<AxiosResponse> => {
    const response = await axios.patch(`module/`, module)
    return response;
}

export const deleteModule = async (id: number): Promise<AxiosResponse> => {
    const response = await axios.delete(`module/${id}`)
    return response;
}