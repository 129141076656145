import * as React from 'react';

import Notiflix from 'notiflix';
import RichText from 'src/components/common/richText/RichText';
import LoaderModal from 'src/components/common/modals/LoaderModal';
import ModuleList, { IModuleListElement } from 'src/components/common/lists/ModuleList';

import { Label, PrimaryButton, DefaultButton } from '@fluentui/react';
import { createInvoice, downloadInvoice, IInvoicePostDto } from 'src/api/invoice';
import { DocType, IInvoiceDto, IModuleDto, InvoiceType, IProjectDto } from 'src/common/dto';
import { downloadFile, setTextAboveAndBelow } from 'src/common/functions';

interface InvoiceFormProps {
    project: IProjectDto;
    closeModal: () => void;
    setProject: (project: IProjectDto) => void
    invoiceType: InvoiceType;
    lastPaymentDate: Date;
}

interface InvoiceFormState {
    modules: IModuleDto[];
    invoice: IInvoicePostDto | null
    chosenModules: IModuleDto[]
    loadingModalOpen: boolean;
}

class InvoiceForm extends React.Component<InvoiceFormProps, InvoiceFormState> {
    constructor(props: InvoiceFormProps) {
        super(props);
        this.state = {
            modules: JSON.parse(JSON.stringify(this.props.project.modules)),
            chosenModules: [],
            invoice: null,
            loadingModalOpen: false,
        };
    }

    componentDidMount = (): void => {
        const invoice: IInvoicePostDto = {
            modules: [],
            projectId: this.props.project.id,
            textAbove: '',
            textBelow: '',
            lastPaymentDate: this.props.lastPaymentDate,
            type: InvoiceType[this.props.invoiceType],

        };
        
        setTextAboveAndBelow(this.props.project, invoice, undefined)

        this.setState({ invoice })
    }

    changeTextAbove = (text: string): void => {
        const invoice = this.state.invoice;
        if (invoice) {
            invoice.textAbove = text;
            this.setState({ invoice: invoice })
        }
    }

    changeTextBelow = (text: string): void => {
        const invoice = this.state.invoice;
        if (invoice) {
            invoice.textBelow = text;
            this.setState({ invoice: invoice })
        }
    }

    onSelectionClick = (modules: IModuleListElement[]): void => {
        const chosenModules: IModuleDto[] = [];

        modules.forEach(m => {
            chosenModules.push(this.state.modules.filter(module => module.id === +m.key)[0]);
        })

        this.setState({ chosenModules })
    }

    onCreateClick = async (): Promise<void> => {
        this.setState({ loadingModalOpen: true })
        const invoice = this.state.invoice;

        if (invoice) {
            this.state.chosenModules.map(m => invoice.modules.push(m));

            const response = await createInvoice(invoice);

            if (response.status === 201) {
                const createdInvoice = response.data as IInvoiceDto;

                const downloadResponse = downloadInvoice(createdInvoice.id);

                downloadResponse.then(r => {
                    if (r.status === 200) {
                        downloadFile(r);

                        const project = this.props.project;
                        project.invoices.push(createdInvoice);
                        project.defaultInvoiceTextAbove = invoice.textAbove;
                        project.defaultInvoiceTextBelow = invoice.textBelow;
                        project.customerDefaultInvoiceTextAbove = invoice.textAbove;
                        project.customerDefaultInvoiceTextBelow = invoice.textBelow;
                        Notiflix.Notify.success('Invoice successfully created');
                        this.setState({ loadingModalOpen: false })
                        this.props.setProject(project);
                        this.props.closeModal();
                    }
                })
            }

        }
    }

    render(): React.ReactElement<InvoiceForm> {
        return this.state.invoice ? (
            <div className='w-1000 flex flex-col gap-4'>
                {this.state.modules.length > 0 ?
                    <ModuleList
                        tax={this.props.project.tax}
                        modules={this.state.modules}
                        onSelectionClick={this.onSelectionClick}
                        documentType={DocType.Invoice}
                    />
                    : null
                }
                <div className='max-w-full'>
                    <Label>Text above</Label>
                    <RichText
                        content={this.state.invoice.textAbove}
                        setContent={this.changeTextAbove}
                    />
                </div>
                <div className='max-w-full'>
                    <Label>Text below</Label>
                    <RichText
                        content={this.state.invoice.textBelow}
                        setContent={this.changeTextBelow}
                    />
                </div>

                <div className='flex gap-5'>
                    <PrimaryButton
                        text='Create'
                        className={this.state.chosenModules.length === 0 ? 'disabled-button' : 'primary-button'}
                        disabled={this.state.chosenModules.length === 0}
                        onClick={this.onCreateClick}
                    />
                    <DefaultButton
                        text='Cancel'
                        onClick={this.props.closeModal}
                    />
                </div>

                <LoaderModal isOpen={this.state.loadingModalOpen} label='Invoice is creating, please wait' />
            </div>
        ) : <div className='w-1000 h-screen'></div>
    }
}

export default InvoiceForm;