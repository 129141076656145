import * as React from 'react';

import EasyDeleteModal from 'src/components/common/modals/EasyDeleteModal';

import { PrimaryButton, TextField } from '@fluentui/react';
import { IAddressPostDto, IAddressPutDto } from 'src/api/address';
import { buttonColor } from 'src/common/button-style';
import { IAddressDto } from 'src/common/dto';

interface AddressFormProps {
    addMode?: {
        customerId: number,
        addAddress: (address: IAddressPostDto) => void
    }
    editMode?: {
        address: IAddressDto;
        updateAddress: (id: number, address: IAddressPutDto) => void
        deleteAddress: (id: number) => void
    }
    buttonText: string
}

interface AddressFormState {
    address: {
        streetHouse: string;
        department: string;
        zipCode: string;
        country: string;
        city: string;
    },
    errorMessages: {
        streetHouseRequired: string;
        zipCodeRequired: string;
    }
    isDeleteModalOpen: boolean;
}

class AddressForm extends React.Component<AddressFormProps, AddressFormState> {
    constructor(props: AddressFormProps) {
        super(props);
        this.state = {
            address: {
                streetHouse: this.props.editMode?.address.streetHouse || '',
                department: this.props.editMode?.address.department || '',
                zipCode: this.props.editMode?.address.zipCode || '',
                country: this.props.editMode?.address.country || '',
                city: this.props.editMode?.address.city || '',
            },
            errorMessages: {
                streetHouseRequired: '',
                zipCodeRequired: ''
            },
            isDeleteModalOpen: false
        }
    }

    changeDeleteModalState = (): void => {
        this.setState({ isDeleteModalOpen: !this.state.isDeleteModalOpen })
    }

    sendRequest = (): void => {
        const address = {
            streetHouse: this.state.address.streetHouse,
            department: this.state.address.department !== '' ? this.state.address.department : null,
            zipCode: this.state.address.zipCode,
            country: this.state.address.country !== '' ? this.state.address.country : null,
            city: this.state.address.city !== '' ? this.state.address.city : null,
        }

        if (this.props.editMode) {
            this.props.editMode.updateAddress(this.props.editMode.address.id, address)
        }
        else if (this.props.addMode) {
            this.props.addMode.addAddress({
                streetHouse: address.streetHouse,
                department: address.department,
                zipCode: address.zipCode,
                country: address.country,
                city: address.city,
                customerId: this.props.addMode.customerId
            })
        }
    }

    isButtonDisable = (): boolean => {
        const address = {
            streetHouse: this.state.address.streetHouse,
            department: this.state.address.department !== '' ? this.state.address.department : null,
            zipCode: this.state.address.zipCode,
            country: this.state.address.country !== '' ? this.state.address.country : null,
            city: this.state.address.city !== '' ? this.state.address.city : null,
        }

        if (address.streetHouse === '' || address.zipCode === '')
            return true

        else if (address.streetHouse !== this.props.editMode?.address.streetHouse)
            return false

        else if (address.department !== this.props.editMode?.address.department)
            return false

        else if (address.zipCode !== this.props.editMode?.address.zipCode)
            return false

        else if (address.country !== this.props.editMode?.address.country)
            return false

        else if (address.city !== this.props.editMode?.address.city)
            return false

        return true
    }

    changeStreetHouse = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void => {
        const address = this.state.address;
        address.streetHouse = newValue || ''
        const errorMessages = this.state.errorMessages;
        if (address.streetHouse === '')
            errorMessages.streetHouseRequired = 'Street/House is required'
        else
            errorMessages.streetHouseRequired = ''

        this.setState({ address: address, errorMessages: errorMessages })
    }

    changeDepartment = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void => {
        const address = this.state.address;
        address.department = newValue || ''
        this.setState({ address: address })
    }

    changeZipCode = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void => {
        const address = this.state.address;
        address.zipCode = newValue || ''
        const errorMessages = this.state.errorMessages;
        if (address.zipCode === '')
            errorMessages.zipCodeRequired = 'Zip code is required'
        else
            errorMessages.zipCodeRequired = ''

        this.setState({ address: address, errorMessages: errorMessages })
    }

    changeCountry = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void => {
        const address = this.state.address;
        address.country = newValue || ''
        this.setState({ address: address })
    }

    changeCity = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void => {
        const address = this.state.address;
        address.city = newValue || ''
        this.setState({ address: address })
    }

    onDeleteClick = (): void => {
        if (this.props.editMode) {
            this.props.editMode.deleteAddress(this.props.editMode.address.id)
            this.resetValues()
            this.changeDeleteModalState()
        }
    }

    resetValues = (): void => {
        this.setState({ address: { streetHouse: '', department: '', zipCode: '', country: '', city: '' } })
    }

    render(): React.ReactElement<AddressForm> {
        return (
            <div>
                <div className='w-80 flex flex-col gap-4'>
                    <TextField
                        label='Street/House'
                        required
                        value={this.state.address.streetHouse}
                        onChange={this.changeStreetHouse}
                        errorMessage={this.state.errorMessages.streetHouseRequired}
                    />
                    <TextField
                        label='City'
                        value={this.state.address.city}
                        onChange={this.changeCity}
                    />
                    <TextField
                        label='Country'
                        value={this.state.address.country}
                        onChange={this.changeCountry}
                    />
                    <TextField
                        label='Department'
                        value={this.state.address.department}
                        onChange={this.changeDepartment}
                    />
                    <TextField
                        label='Zip code'
                        required
                        value={this.state.address.zipCode}
                        onChange={this.changeZipCode}
                        errorMessage={this.state.errorMessages.zipCodeRequired}
                    />
                </div>

                <div className='flex gap-5 mt-6'>
                    <PrimaryButton
                        disabled={this.isButtonDisable()}
                        className={buttonColor(this.isButtonDisable())}
                        text={this.props.buttonText}
                        onClick={this.sendRequest}
                    />
                    {this.props.editMode
                        ?
                        <PrimaryButton
                            className='delete-button'
                            text='Delete'
                            onClick={this.changeDeleteModalState}
                        />
                        : null
                    }
                </div>

                <EasyDeleteModal
                    closeModal={this.changeDeleteModalState}
                    isOpen={this.state.isDeleteModalOpen}
                    onDeleteClick={this.onDeleteClick}
                    deleteEntity='address'
                />
            </div>
        );
    }
}

export default AddressForm;