import "./input.css"

import ReactDOM from 'react-dom';
import Notiflix from 'notiflix';

import { App } from './App';
import { BrowserRouter } from "react-router-dom";

ReactDOM.render(<BrowserRouter><App/></BrowserRouter>, document.getElementById('root'));

Notiflix.Notify.init({
    zindex: 1000001,
    position: 'right-bottom',
    distance: '10px',
    clickToClose: true,
    pauseOnHover: true
})