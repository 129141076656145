import { API_SCOPE, CLIENT_ID, TENANT_ID } from "src/common/constants";

const redirectUri = window.location.protocol + '//' + window.location.host

export const msalConfig = {   
  auth: {
    clientId: CLIENT_ID,  // Client ID of the React.JS App Registration
    authority: TENANT_ID, // Tenant ID of the React.JS App Registration
    redirectUri: redirectUri,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Can be found in the API Permissions of the ASP.NET Web API
export const loginApiRequest = {
  scopes: [API_SCOPE],
};