import * as React from 'react';

import RockettaModal from './RockettaModal';
import { DefaultButton, PrimaryButton } from '@fluentui/react';

interface ImportantDeleteModalProps {
    isOpen: boolean
    closeModal: () => void
    deleteEntity: string
    onDeleteClick: () => void
}

interface ImportantDeleteModalState {

}

class ImportantDeleteModal extends React.Component<ImportantDeleteModalProps, ImportantDeleteModalState> {
    constructor(props: ImportantDeleteModalProps) {
        super(props);
    }
    render(): React.ReactElement<ImportantDeleteModal> {
        return (
            <RockettaModal
                closeModal={this.props.closeModal}
                isOpen={this.props.isOpen}
                name={'Delete ' + this.props.deleteEntity + '?'}
            >
                <>
                    <div className='text-gray-600 w-96'>
                        All information which connected with {this.props.deleteEntity} will be deleted permanently.
                        Are you sure you want to delete?
                    </div>

                    <div className='flex float-right mt-5'>
                        <PrimaryButton
                            className='delete-button mr-4'
                            onClick={this.props.onDeleteClick}
                            text='Delete'
                        />
                        <DefaultButton
                            onClick={this.props.closeModal}
                            text='Cancel'
                        />
                    </div>
                </>
            </RockettaModal>
        );
    }
}

export default ImportantDeleteModal;