import * as React from 'react';

import { Text } from '@fluentui/react';

interface PartNameProps {
    name: string
    className?: string;
}

interface PartNameState {

}

class PartName extends React.Component<PartNameProps, PartNameState> {
    render(): React.ReactElement<PartName> {
        return (
            <div>
                <Text className={'text-xl ' + this.props.className}>
                    {this.props.name}
                </Text>
            </div>
        );
    }
}

export default PartName;