import * as React from 'react';

import { DetailsList, DetailsListLayoutMode, SelectionMode, Selection, IColumn, CheckboxVisibility, SpinButton, ISelection, Text } from '@fluentui/react';
import { DocType, IModuleDto } from 'src/common/dto';
import { parseStringToNumber, showModuleName, showPrice } from 'src/common/functions';
import { MAX_QTY } from 'src/common/constants';

interface ModuleListProps {
    tax: number;
    modules: IModuleDto[];
    onSelectionClick: (modules: IModuleListElement[]) => void;
    documentType: DocType;
}

interface ModuleListState {
    modules: IModuleDto[];
    shownModules: IModuleListElement[];
    columns: IColumn[];
    changed: boolean;
    selection: ISelection;
    selectedKeys: string[];
}

export interface IModuleListElement {
    key: string;
    moduleName: JSX.Element;
    quantity: JSX.Element;
    price: string;
    total: string;
    timeInterval: string;
    isOptional: string;
}

class ModuleList extends React.Component<ModuleListProps, ModuleListState> {
    constructor(props: ModuleListProps) {
        super(props);
        this.state = {
            modules: this.props.modules,
            shownModules: this.renderModules(this.props.modules),
            columns: this.renderColumns(),
            changed: false,
            selection: new Selection({
                onSelectionChanged: () => {
                    this.props.onSelectionClick(this.state.selection.getSelection().map(s => s as IModuleListElement))
                    this.setState({ selectedKeys: this.state.selection.getSelection().map(s => s as IModuleListElement).map(m => m.key) })
                },
            }),
            selectedKeys: [],
        };
    }

    componentDidMount(): void {
        const selection = this.state.selection;
        const selectedKeys = this.state.selectedKeys;
        const selectedModules = this.props.documentType === DocType.Quotation ? this.props.modules : this.props.modules.filter(m => m.isOptional === false);
        selectedModules.forEach(s => {
            const key = `${s.id}`
            selection.setKeySelected(key, true, true)
            selectedKeys.push(key);
        });
        this.setState({ selection, selectedKeys })
    }

    componentDidUpdate(prevProps: Readonly<ModuleListProps>, prevState: Readonly<ModuleListState>): void {
        if (prevState.shownModules !== this.state.shownModules) {
            const selection = this.state.selection;
            this.state.selectedKeys.forEach(k =>
                selection.setKeySelected(k, true, true)
            );
            this.setState({ selection })
        }
    }

    renderColumns = (): IColumn[] => {
        const columns: IColumn[] = [];
        columns.push(
            { key: '0', minWidth: 80, name: 'Positions', fieldName: 'moduleName' },
            { key: '1', minWidth: 100, name: 'Quantity', fieldName: 'quantity' },
            { key: '2', minWidth: 100, name: 'Time interval', fieldName: 'timeInterval' },
            { key: '3', minWidth: 70, name: 'Optional', fieldName: 'isOptional' },
            { key: '4', minWidth: 70, name: 'Price', fieldName: 'price' },
            { key: '5', minWidth: 100, name: 'Total price', fieldName: 'total' },
        );

        return columns;
    }

    renderModules = (modules: IModuleDto[]): IModuleListElement[] => {
        const elements: IModuleListElement[] = [];
        modules.map(m => {
            const element: IModuleListElement = {
                key: `${m.id}`,
                moduleName: <Text variant='medium'>{showModuleName(m.description)}</Text>,
                price: showPrice(m.price),
                timeInterval: m.timeInterval,
                isOptional: m.isOptional ? 'Yes' : 'No',
                total: showPrice(m.price * m.quantity),
                quantity:
                    <SpinButton
                        value={m.quantity.toString()}
                        min={0}
                        max={MAX_QTY}
                        step={0.5}
                        onChange={(event: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
                            if (newValue && !isNaN(+newValue)) {
                                const modules = this.state.modules;
                                const index = modules.indexOf(m);
                                m.quantity = +(+newValue).toFixed(1);
                                modules[index] = m;

                                this.setState({ modules, shownModules: this.renderModules(modules) })
                            }
                        }}
                    />
            }

            elements.push(element);
        })

        return elements;
    }

    render(): React.ReactElement<ModuleList> {
        const selectedPositions = this.state.selection.getSelection().map(s => s as IModuleListElement);
        let sum = 0;
        selectedPositions.forEach(p => {
            if (this.props.documentType === DocType.Invoice || this.props.modules.find(m => m.id === +p.key)?.isOptional === false)
                sum += parseStringToNumber(p.total)
        });
        const tax = sum / 100 * this.props.tax;
        const total = tax + sum;

        return (
            <div>
                <DetailsList
                    selection={this.state.selection}
                    selectionPreservedOnEmptyClick={true}
                    items={this.state.shownModules}
                    columns={this.state.columns}
                    selectionMode={SelectionMode.multiple}
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                    checkboxVisibility={CheckboxVisibility.onHover}
                    className='header-p-0 overflow-auto'
                />

                <div className='flex justify-end'>
                    <DetailsList
                        columns={[
                            { key: '0', minWidth: 70, name: 'Text', fieldName: 'text' },
                            { key: '1', minWidth: 100, name: 'Price', fieldName: 'price' },
                        ]}
                        items={this.props.documentType === DocType.Invoice ?
                            [
                                { text: 'Sum:', price: showPrice(sum) },
                                { text: 'Tax:', price: showPrice(tax) },
                                { text: 'Total:', price: showPrice(total) },
                            ]
                            :
                            [
                                { text: 'Sum:', price: showPrice(sum) }
                            ]
                        }
                        selectionMode={SelectionMode.none}
                        isHeaderVisible={false}
                        className='header-p-0 overflow-auto'
                    />
                </div>
            </div>
        );
    }
}

export default ModuleList;