import * as React from 'react';

import Notiflix from 'notiflix';

import { AxiosError } from 'axios';
import { getAllQuotations } from 'src/api/quotation';
import { IQuotationDto } from 'src/common/dto';
import QuotationList from 'src/components/common/lists/QuotationList';
import { ProgressIndicator } from '@fluentui/react';
import { setDocumentTitle } from 'src/common/functions';

interface QuotationsPageProps {

}

interface QuotationsPageState {
    quotations: IQuotationDto[];
    loaded: boolean;
}

class QuotationsPage extends React.Component<QuotationsPageProps, QuotationsPageState> {
    constructor(props: QuotationsPageProps) {
        super(props);
        this.state = {
            quotations: [],
            loaded: false
        };
    }

    componentDidMount(): void {
        setDocumentTitle('Quotations')
        
        const response = getAllQuotations()

        response.then(r => {
            if (r.status === 200) {
                this.setState({ quotations: r.data, loaded: true })
            }
        })
        response.catch((error: AxiosError) => {
            Notiflix.Notify.failure(error.message);
        })
    }

    render(): React.ReactElement<QuotationsPage> {
        return this.state.loaded ? (
            <div className='p-5'>
                <QuotationList
                    quotations={this.state.quotations}
                    showFilters
                />
            </div>
        ) : <ProgressIndicator className='mt-m-056' />
    }
}

export default QuotationsPage;