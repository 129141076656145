import * as React from 'react';

import { IconButton, Modal, Text } from '@fluentui/react';
import { cancelIcon } from 'src/common/icons';

interface RockettaModalProps {
    closeModal: () => void;
    isOpen: boolean;
    name?: string;
    className?: string
}

interface RockettaModalState {

}

class RockettaModal extends React.Component<RockettaModalProps, RockettaModalState> {
    constructor(props: RockettaModalProps) {
        super(props);
    }

    render(): React.ReactElement<RockettaModal> {
        return (
            <Modal
                isOpen={this.props.isOpen}
                containerClassName={"items-stretch w-98 overflow-hidden flex flex-nowrap " + this.props.className}
                onDismiss={this.props.closeModal}
            >
                <div className='text-right'>
                    <IconButton iconProps={cancelIcon}
                        onClick={this.props.closeModal}
                    />
                </div>
                {this.props.name && <div className='text-center'><Text variant='large'>{this.props.name}</Text></div>}
                <div className='overflow-hidden p-7 pt-4'>{this.props.children}</div>
            </Modal>
        );
    }
}

export default RockettaModal;