import axios, { AxiosResponse } from "axios";
import { Language } from "src/common/dto";

export interface IProjectPostRequest {
    name: string;
    defaultPricePerDay: number;
    customerId: number;
    tax: number;
    status: string;
    customerOrderNo: string;
}

export interface IProjectPutRequest {
    name: string;
    defaultPricePerDay: number;
    tax: number;
    status: string;
    customerOrderNo: string;
}

export const addProject = async (project: IProjectPostRequest, language: Language): Promise<AxiosResponse> => {
    const response = await axios.post(`project?language=${language}`, project)
    return response;
}

export const updateProject = async (id: number, project: IProjectPutRequest, language: Language): Promise<AxiosResponse> => {
    const response = await axios.put(`project/${id}?language=${language}`, project)
    return response;
}

export const getProject = async (id: number): Promise<AxiosResponse> => {
    const response = await axios.get(`project/${id}`)
    return response;
}

export const getAllProjects = async (): Promise<AxiosResponse> => {
    const response = await axios.get(`project`)
    return response;
}

export const deleteProject = async (id: number): Promise<AxiosResponse> => {
    const response = await axios.delete(`project/${id}`)
    return response;
}