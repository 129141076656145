import * as React from 'react';

import TextAlign from '@tiptap/extension-text-align';
import StarterKit from '@tiptap/starter-kit';
import MenuBar from './MenuBar';

import { EditorContent, useEditor } from '@tiptap/react';

interface RichTextProps {
    content: string;
    setContent: (content: string) => void
}

const RichText: React.FunctionComponent<RichTextProps> = (props) => {
    const editor = useEditor({
        extensions: [
            StarterKit,
            TextAlign.configure({
                types: ["heading", "paragraph"],
            })
        ],
        content: props.content
    });

    const onChange = (): void => {
        if (editor !== null) {
            const newContent = editor.getHTML()
            props.setContent(newContent);
        }
    }

    return (
        <div className="border border-color-fluent p-2" onKeyUp={onChange} onClick={onChange}>
            <MenuBar editor={editor} />
            <EditorContent editor={editor} />
        </div>
    )
}

export default RichText;