import * as React from 'react';
import { ICustomerDto, IEMailSetting, Language } from 'src/common/dto';
import PartName from '../common/PartName';
import { PrimaryButton, TextField } from '@fluentui/react';
import { buttonColor } from 'src/common/button-style';
import { ICustomerPostRequest } from 'src/api/customer';

interface EMailSettingsProps {
    customer: ICustomerDto;
    editCustomer: (id: number, customer: ICustomerPostRequest, language: Language) => void
}

interface EMailSettingsState {
    emailSettings: IEMailSetting;
}

class EMailSettings extends React.Component<EMailSettingsProps, EMailSettingsState> {
    constructor(props: EMailSettingsProps) {
        super(props);
        this.state = {
            emailSettings: {
                cc: this.props.customer.cc || '',
                to: this.props.customer.to || '',
                text: this.props.customer.text || '',
                subject: this.props.customer.subject || ''
            }
        };
    }

    setRecipient = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string | undefined): void => {
        const emailSettings = this.state.emailSettings;
        emailSettings.to = newValue || '';
        this.setState({ emailSettings })
    }

    setCC = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string | undefined): void => {
        const emailSettings = this.state.emailSettings;
        emailSettings.cc = newValue || '';
        this.setState({ emailSettings })
    }

    setSubject = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string | undefined): void => {
        const emailSettings = this.state.emailSettings;
        emailSettings.subject = newValue || '';
        this.setState({ emailSettings })
    }

    setText = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string | undefined): void => {
        const emailSettings = this.state.emailSettings;
        emailSettings.text = newValue || '';
        this.setState({ emailSettings })
    }

    onSaveClick = async (): Promise<void> => {
        const customer: ICustomerPostRequest = {
            name: this.props.customer.name,
            contractor: this.props.customer.contractor !== '' ? this.props.customer.contractor : null,
            defaultPricePerDay: this.props.customer.defaultPricePerDay,
            tax: this.props.customer.tax,
            text: this.state.emailSettings.text !== '' ? this.state.emailSettings.text : null,
            cc: this.state.emailSettings.cc !== '' ? this.state.emailSettings.cc : null,
            to: this.state.emailSettings.to !== '' ? this.state.emailSettings.to : null,
            subject: this.state.emailSettings.subject !== '' ? this.state.emailSettings.subject : null,
        }

        this.props.editCustomer(this.props.customer.id, customer, this.props.customer.language)
    }

    isButtonDisable = (): boolean => {
        if (!this.isEqual(this.props.customer.cc, this.state.emailSettings.cc))
            return false;
        if (!this.isEqual(this.props.customer.to, this.state.emailSettings.to))
            return false;
        if (!this.isEqual(this.props.customer.text, this.state.emailSettings.text))
            return false;
        if (!this.isEqual(this.props.customer.subject, this.state.emailSettings.subject))
            return false;

        return true;
    }

    isEqual = (value1: string | null, value2: string | null): boolean => {
        if (value1 === value2)
            return true;

        if (value1 === null && value2 === '')
            return true;

        if (value2 === null && value1 === '')
            return true;

        return false;
    }

    render(): React.ReactElement<EMailSettings> {
        return (
                <div className='flex flex-col gap-4 w-80'>
                    <PartName name='E-Mail for Invoices' />

                    <TextField
                        label='Recipient'
                        value={this.state.emailSettings.to}
                        onChange={this.setRecipient}
                    />
                    <TextField
                        label='CC'
                        value={this.state.emailSettings.cc}
                        onChange={this.setCC}
                    />
                    <TextField
                        label='Subject'
                        value={this.state.emailSettings.subject}
                        onChange={this.setSubject}
                    />
                    <TextField
                        label='Text'
                        multiline
                        value={this.state.emailSettings.text}
                        onChange={this.setText}
                        placeholder='[CustomerName], [ProjectName], [ProjectStatus], [LastPaymentDate], [OrderNumber]'
                    />

                    <div>
                        <PrimaryButton
                            text='Save'
                            className={buttonColor(this.isButtonDisable())}
                            disabled={this.isButtonDisable()}
                            onClick={this.onSaveClick}
                        />
                    </div>
                </div>
        );
    }
}

export default EMailSettings;