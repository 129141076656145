import * as React from 'react';

import { Persona } from '@fluentui/react';
import { IContactPersonDto } from 'src/common/dto';

interface ContactPersonProps {
    person: IContactPersonDto
    className?: string
}

interface ContactPersonState {

}

class ContactPerson extends React.Component<ContactPersonProps, ContactPersonState> {
    constructor(props: ContactPersonProps) {
        super(props);
    }

    getPersonaText = (): string => {
        let text = this.props.person.firstName;

        if (this.props.person.lastName !== null)
            text += ' ' + this.props.person.lastName;

        if (this.props.person.email !== null)
            text += ' | ' + this.props.person.email

        return text;
    }

    getImageInit = (): string => {
        let init = this.props.person.firstName[0];

        if (this.props.person.lastName !== null)
            init += this.props.person.lastName[0]

        return init.toUpperCase();
    }

    render(): React.ReactElement<ContactPerson> {
        return (
            <Persona
                className={this.props.className}
                imageInitials={this.getImageInit()}
                text={this.getPersonaText()}
                secondaryText={this.props.person.phone || undefined}
            />
        );
    }
}

export default ContactPerson;