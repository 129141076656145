import * as React from 'react';

import { PrimaryButton, TextField } from '@fluentui/react';
import { IContactPersonPostRequest, IContactPersonPutRequest } from 'src/api/contactPerson';
import { buttonColor } from 'src/common/button-style';
import { IContactPersonDto } from 'src/common/dto';

interface ContactPersonFormProps {
    addCustomerMode?: {
        customerId: number;
        addContactPerson: (person: IContactPersonPostRequest) => void;
    }
    editCustomerMode?: {
        contactPerson: IContactPersonDto;
        updateContactPerson: (id: number, person: IContactPersonPutRequest) => void
    }
    buttonName: string
}

interface ContactPersonFormState {
    contactPerson: {
        firstName: string,
        lastName: string,
        phone: string,
        email: string
    }
    errorMessages: {
        nameRequired: string
    }
}

class ContactPersonForm extends React.Component<ContactPersonFormProps, ContactPersonFormState> {
    constructor(props: ContactPersonFormProps) {
        super(props);
        this.state = {
            contactPerson: {
                firstName: this.props.editCustomerMode?.contactPerson.firstName || '',
                lastName: this.props.editCustomerMode?.contactPerson.lastName || '',
                phone: this.props.editCustomerMode?.contactPerson.phone || '',
                email: this.props.editCustomerMode?.contactPerson.email || ''
            },
            errorMessages: {
                nameRequired: ''
            }
        }
    }

    sendRequest = (): void => {
        const contactPerson = {
            firstName: this.state.contactPerson.firstName,
            lastName: this.state.contactPerson.lastName !== '' ? this.state.contactPerson.lastName : null,
            email: this.state.contactPerson.email !== '' ? this.state.contactPerson.email : null,
            phone: this.state.contactPerson.phone !== '' ? this.state.contactPerson.phone : null
        }

        if (this.props.editCustomerMode)
            this.props.editCustomerMode.updateContactPerson(this.props.editCustomerMode.contactPerson.id, contactPerson)

        else if (this.props.addCustomerMode) {
            this.props.addCustomerMode.addContactPerson({
                firstName: contactPerson.firstName,
                lastName: contactPerson.lastName,
                email: contactPerson.email,
                phone: contactPerson.phone,
                customerId: this.props.addCustomerMode.customerId
            })
        }
    }

    isButtonDisable = (): boolean => {
        const contactPerson = {
            firstName: this.state.contactPerson.firstName,
            lastName: this.state.contactPerson.lastName !== '' ? this.state.contactPerson.lastName : null,
            email: this.state.contactPerson.email !== '' ? this.state.contactPerson.email : null,
            phone: this.state.contactPerson.phone !== '' ? this.state.contactPerson.phone : null
        }

        if (contactPerson.firstName === '')
            return true;

        else if (contactPerson.firstName !== this.props.editCustomerMode?.contactPerson.firstName)
            return false;

        else if (contactPerson.lastName !== this.props.editCustomerMode?.contactPerson.lastName)
            return false;

        else if (contactPerson.email !== this.props.editCustomerMode.contactPerson.email)
            return false;

        else if (contactPerson.phone !== this.props.editCustomerMode.contactPerson.phone)
            return false;

        else return true;
    }

    setFirstName = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string | undefined): void => {
        const person = this.state.contactPerson;
        person.firstName = newValue || ''
        const errorMessages = this.state.errorMessages;
        if (person.firstName === '')
            errorMessages.nameRequired = 'Name is required'
        else
            errorMessages.nameRequired = ''
        this.setState({ contactPerson: person, errorMessages: errorMessages })
    }

    setLastNameName = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string | undefined): void => {
        const person = this.state.contactPerson;
        person.lastName = newValue || ''
        this.setState({ contactPerson: person })
    }

    setPhone = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string | undefined): void => {
        const person = this.state.contactPerson;
        person.phone = newValue || ''
        this.setState({ contactPerson: person })
    }

    setEmail = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string | undefined): void => {
        const person = this.state.contactPerson;
        person.email = newValue || ''
        this.setState({ contactPerson: person })
    }

    render(): React.ReactElement<ContactPersonForm> {
        return (
            <div>
                <div className='w-80 flex flex-col gap-4'>
                    <TextField
                        label='First name' required
                        onChange={this.setFirstName}
                        value={this.state.contactPerson.firstName}
                        errorMessage={this.state.errorMessages.nameRequired}
                    />
                    <TextField
                        label='Last name'
                        onChange={this.setLastNameName}
                        value={this.state.contactPerson.lastName}
                    />
                    <TextField
                        label='Email'
                        onChange={this.setEmail}
                        value={this.state.contactPerson.email}
                    />
                    <TextField
                        label='Phone'
                        onChange={this.setPhone}
                        value={this.state.contactPerson.phone}
                    />
                </div>

                <PrimaryButton onClick={this.sendRequest}
                    disabled={this.isButtonDisable()}
                    className={buttonColor(this.isButtonDisable()) + ' mt-6'}
                    text={this.props.buttonName}
                />
            </div>
        );
    }
}

export default ContactPersonForm;