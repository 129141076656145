import * as React from 'react';

import ContactPeople from 'src/components/customerSettingsPage/ContactPeople';
import Line from 'src/components/common/Line';
import Projects from 'src/components/customerSettingsPage/Projects';
import PartName from 'src/components/common/PartName';
import CustomerForm from 'src/forms/CustomerForm';
import Address from 'src/components/customerSettingsPage/Address';
import DeleteModal from 'src/components/common/modals/ImportantDeleteModal';
import Notiflix from 'notiflix';

import { PrimaryButton, ProgressIndicator, Text } from '@fluentui/react';
import { Navigate } from 'react-router-dom';
import { deleteCustomer, getCustomer, ICustomerPostRequest, updateCustomer } from 'src/api/customer';
import { ICustomerDto, Language } from 'src/common/dto';
import { AxiosError } from 'axios';
import EmailSettings from 'src/components/customerSettingsPage/EMailSettings';
import { setDocumentTitle } from 'src/common/functions';

interface CustomerSettingsPageProps {

}

interface CustomerSettingsPageState {
    customer: ICustomerDto;
    isRedirect: boolean;
    isNotFound: boolean;
    deleteCustomerModalOpen: boolean;
    loaded: boolean;
}

class CustomerSettingsPage extends React.Component<CustomerSettingsPageProps, CustomerSettingsPageState> {
    constructor(props: CustomerSettingsPageProps) {
        super(props);
        this.state = {
            customer: {} as ICustomerDto,
            isRedirect: false,
            isNotFound: false,
            deleteCustomerModalOpen: false,
            loaded: false
        }
    }

    getCustomerId = (pathName: string): string => {
        const directories = pathName.split("/");
        const lastDirectory = directories[(directories.length - 2)];
        return lastDirectory;
    }

    componentDidMount = (): void => {
        const path = window.location.pathname;
        const id = this.getCustomerId(path);
        this.getCustomer(parseInt(id));
    }

    setCustomerState = (customer: ICustomerDto): void => {
        this.setState({ customer: customer })
    }

    getCustomer = (id: number): void => {
        const response = getCustomer(id);

        response.then(r => {
            if (r.status === 200) {
                const customer: ICustomerDto = r.data;
                setDocumentTitle(`${customer.name} Settings`);
                this.setState({ customer, loaded: true })
            }
        })
        response.catch((error: AxiosError) => {
            if (error.response?.status === 404)
                this.setState({ isNotFound: true })
            else
                Notiflix.Notify.failure(`${error.response?.data}`)
        })
    }

    deleteCustomer = (): void => {
        const response = deleteCustomer(this.state.customer.id);

        response.then(r => {
            if (r.status === 200) {
                Notiflix.Notify.success('Customer deleted successfully');
                this.setState({ isRedirect: true })
            }
        })
        response.catch((error: AxiosError) => {
            Notiflix.Notify.failure(`${error.response?.data}`)
        })
    }

    updateCustomer = (id: number, customer: ICustomerPostRequest, language: Language): void => {
        const response = updateCustomer(id, customer, language);
        console.log(response)
        response.then(r => {
            if (r.status === 200) {
                const customer = r.data;
                Notiflix.Notify.success('Customer successfully updated');
                this.setCustomerState(customer)
            }
        })
        response.catch((error: AxiosError) => {
            if (error.response?.status === 409)
                Notiflix.Notify.warning('Customer with the same name already exist');
            else
                Notiflix.Notify.failure(error.message);
        })
    }

    changeDeleteCustomerModalState = (): void => {
        this.setState({ deleteCustomerModalOpen: !this.state.deleteCustomerModalOpen })
    }

    render(): React.ReactElement<CustomerSettingsPage> {
        return this.state.loaded ? (
            this.state.isRedirect ? <Navigate to='/customers' replace={true} /> :
                <div className='p-5 flex flex-col gap-4'>
                    <PartName name='Customer details' />
                    <CustomerForm
                        editMode={{
                            customer: this.state.customer,
                            editCustomer: this.updateCustomer,
                            setCustomerState: this.setCustomerState
                        }}
                        buttonText='Save'
                    />
                    <Line />
                    
                    <Address customer={this.state.customer} setCustomerState={this.setCustomerState} />
                    <Line />

                    <EmailSettings customer={this.state.customer} editCustomer={this.updateCustomer}/>
                    <Line />

                    <ContactPeople customer={this.state.customer} setCustomerState={this.setCustomerState} />
                    <Line />

                    <Projects customer={this.state.customer} setCustomerState={this.setCustomerState} />
                    <Line />

                    <div>
                        <PartName name='Delete customer' />
                        <div className='mt-2'>
                            <Text className='text-base text-gray-600'>
                                If you delete this customer all information which connected with him will be deleted permanently
                            </Text>
                        </div>
                        <PrimaryButton
                            className='delete-button mt-5'
                            text='Delete customer'
                            onClick={this.changeDeleteCustomerModalState}
                        />
                    </div>

                    <DeleteModal
                        closeModal={this.changeDeleteCustomerModalState}
                        isOpen={this.state.deleteCustomerModalOpen}
                        onDeleteClick={this.deleteCustomer}
                        deleteEntity={this.state.customer.name}
                    />
                </div>
        ) : this.state.isNotFound ? <Navigate to='/notFound' replace={true} /> : <ProgressIndicator className='mt-m-056' />;
    }
}

export default CustomerSettingsPage;