import * as React from 'react';

interface TableProps {
    className?: string;
}

interface TableState {

}

class Table extends React.Component<TableProps, TableState> {
    constructor(props: TableProps) {
        super(props);
    }

    render(): React.ReactElement<Table> {
        return (
            <div className={'bg-white border-r border-t border-l rounded ' + this.props.className}>
                {this.props.children}
            </div>
        );
    }
}

export default Table;