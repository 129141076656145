import * as React from 'react';

import { PrimaryButton } from '@fluentui/react';
import { Link } from "react-router-dom";
import { setDocumentTitle } from 'src/common/functions';

class NotFound extends React.Component{
    componentDidMount(): void {
        setDocumentTitle('NotFound')
    }
    render(): React.ReactElement<NotFound> { 
        return (
            <>  
                <div className='text-center text-7xl pt-28'>404</div>
                <div className='text-center text-xl mt-5'>Even the things we love break sometimes.</div>
                <div className='text-center mt-5'>
                    <Link to="/"><PrimaryButton text='Home' className='primary-button'/></Link>
                </div>
            </>
        );
    }
}
 
export default NotFound;