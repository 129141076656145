import * as React from 'react';

import ContactPersonForm from 'src/forms/ContactPersonForm';
import ContactPerson from '../common/entities/ContactPerson';
import RockettaModal from '../common/modals/RockettaModal';
import PartName from '../common/PartName';
import Table from '../common/table/Table';
import TableSettingElement from '../common/table/TableSettingElement';
import Notiflix from 'notiflix';

import { PrimaryButton } from '@fluentui/react';
import { AxiosError } from 'axios';
import { addPerson, deletePerson, IContactPersonPostRequest, IContactPersonPutRequest, updatePerson } from 'src/api/contactPerson';
import { IContactPersonDto, ICustomerDto } from 'src/common/dto';
import LoaderModal from '../common/modals/LoaderModal';

interface ContactPeopleProps {
    customer: ICustomerDto;
    setCustomerState: (customer: ICustomerDto) => void
}

interface ContactPeopleState {
    isAddModalOpen: boolean;
    isEditModalOpen: boolean;
    EditPersonId: number;
    loadingModalOpen: boolean;
}

class ContactPeople extends React.Component<ContactPeopleProps, ContactPeopleState> {
    constructor(props: ContactPeopleProps) {
        super(props);
        this.state = { isAddModalOpen: false, isEditModalOpen: false, EditPersonId: 0, loadingModalOpen: false }
    }

    changeAddModalState = (): void => {
        this.setState({ isAddModalOpen: !this.state.isAddModalOpen })
    }

    editClick = (id: number): void => {
        this.setState({ EditPersonId: id })
        this.changeEditModalState();
    }

    changeEditModalState = (): void => {
        this.setState({ isEditModalOpen: !this.state.isEditModalOpen })
    }

    deletePerson = (id: number): void => {
        this.setState({ loadingModalOpen: true })
        const response = deletePerson(id);

        response.then((response) => {
            if (response.status === 200) {
                const person = this.props.customer.contactPeople.find(p => p.id === id)

                if (person !== undefined) {
                    const index = this.props.customer.contactPeople.indexOf(person)
                    const newCustomer = this.props.customer;
                    newCustomer.contactPeople.splice(index, 1);
                    Notiflix.Notify.success('Contact person successfully deleted');
                    this.props.setCustomerState(newCustomer);
                }
            }
        })
        response.catch((error: AxiosError) => {
            Notiflix.Notify.failure(`${error.response?.data}`)
        })
        this.setState({ loadingModalOpen: false })
    }

    addPerson = (person: IContactPersonPostRequest): void => {
        this.setState({ loadingModalOpen: true })
        const response = addPerson(person);

        response.then((response) => {
            if (response.status === 201) {
                const newPerson = response.data as IContactPersonDto
                const newCustomer = this.props.customer;
                newCustomer.contactPeople.push(newPerson);
                Notiflix.Notify.success('Contact person successfully added');
                this.props.setCustomerState(newCustomer);
                this.changeAddModalState();
            }
        })
        response.catch((error: AxiosError) => {
            Notiflix.Notify.failure(`${error.response?.data}`)
        })
        this.setState({ loadingModalOpen: false })
    }

    updatePerson = (id: number, person: IContactPersonPutRequest): void => {
        this.setState({ loadingModalOpen: true })
        const response = updatePerson(id, person);

        response.then((response) => {
            if (response.status === 200) {
                const person = this.props.customer.contactPeople.find(p => p.id === id)
                if (person !== undefined) {
                    const newCustomer = this.props.customer;
                    const newPerson = response.data as IContactPersonDto
                    const index = this.props.customer.contactPeople.indexOf(person)
                    newCustomer.contactPeople[index] = newPerson;
                    Notiflix.Notify.success('Contact person successfully updated');
                    this.props.setCustomerState(newCustomer);
                }

                this.changeEditModalState();
            }
        })
        response.catch((error: AxiosError) => {
            Notiflix.Notify.failure(`${error.response?.data}`)
        })
        this.setState({ loadingModalOpen: false })
    }

    render(): React.ReactElement<ContactPeople> {
        return (
            <div>
                <div className='flex flex-col gap-4'>
                    <PartName name='Contact people' />

                    {this.props.customer?.contactPeople?.length > 0 ?
                        <Table className='min-w-half max-w-fit'>
                            {this.props.customer.contactPeople.map(p =>
                                <TableSettingElement
                                    key={p.id}
                                    itemName={'contact person'}
                                    onDeleteClick={this.deletePerson}
                                    onEditClick={this.editClick}
                                    elementId={p.id}
                                    isImportantDeleting={false}
                                >
                                    <ContactPerson
                                        person={p}
                                    />
                                </TableSettingElement>
                            )}
                        </Table>
                        : null
                    }

                    <div>
                        <PrimaryButton
                            className='mt-2 primary-button'
                            text='Add contact person'
                            onClick={this.changeAddModalState}
                        />
                    </div>
                </div>

                <RockettaModal
                    closeModal={this.changeAddModalState}
                    isOpen={this.state.isAddModalOpen}
                    name='Add contact person'
                >
                    <ContactPersonForm
                        addCustomerMode={{
                            customerId: this.props.customer.id,
                            addContactPerson: this.addPerson
                        }}

                        buttonName='Add'
                    />
                </RockettaModal>

                <RockettaModal
                    closeModal={this.changeEditModalState}
                    isOpen={this.state.isEditModalOpen}
                    name='Edit contact person'
                >
                    <ContactPersonForm
                        editCustomerMode={{
                            contactPerson: this.props.customer.contactPeople
                                .find(c => c.id === this.state.EditPersonId) || {} as IContactPersonDto,
                            updateContactPerson: this.updatePerson
                        }}
                        buttonName='Save'
                    />
                </RockettaModal>

                <LoaderModal isOpen={this.state.loadingModalOpen} label='Please wait' />
            </div>
        );
    }
}

export default ContactPeople;



