import * as React from 'react';

import PartName from '../PartName';
import RockettaModal from '../modals/RockettaModal';
import InvoiceEditForm from 'src/forms/InvoiceEditForm';
import InvoiceStatus from '../InvoiceStatus';

import { IconButton, Text } from '@fluentui/react';
import { IInvoiceDto } from 'src/common/dto';
import { downloadFile, getPaymentsPrice, showDate, showPrice } from 'src/common/functions';
import { downloadIcon } from 'src/common/icons';
import { downloadInvoice } from 'src/api/invoice';
import LoaderModal from '../modals/LoaderModal';
import { AxiosError } from 'axios';
import Notiflix from 'notiflix';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { ColumnFilterItem } from 'antd/es/table/interface';

interface InvoiceListProps {
    invoices: IInvoiceDto[]
    changeEditFormState: () => void
    isEditFormOpen: boolean
    showFilters?: true
    updateInvoice: (invoice: IInvoiceDto) => void
}

interface InvoiceListState {
    columns: ColumnsType<IInvoiceDto>;
    editInvoice: IInvoiceDto | undefined;
    loadingModalOpen: boolean;
}

export enum IPaymentStatus {
    Paid,
    Unpaid,
    Overpaid
}

class InvoiceList extends React.Component<InvoiceListProps, InvoiceListState> {
    constructor(props: InvoiceListProps) {
        super(props);
        this.state = {
            columns: [],
            editInvoice: undefined,
            loadingModalOpen: false
        };
    }

    componentDidMount(): void {
        const columns: ColumnsType<IInvoiceDto> = [
            {
                key: 'number',
                dataIndex: 'number',
                title: 'Number',
                render: (text, record) => <Text className='hover-blue' onClick={() => this.onInvoiceClick(record)}>{text}</Text>,
                sorter: (a, b) => a.number.localeCompare(b.number),
                filters: this.props.invoices
                    .map(r => { return { text: r.number, value: r.number } })
                    .reduce((accum: ColumnFilterItem[], val: ColumnFilterItem) => accum.some(v => v.text === val.text) ? accum : [...accum, val], []),
                filterSearch: true,
                onFilter: (value, record) => {
                    return record.number === value
                },
            },
            {
                key: 'price',
                dataIndex: 'price',
                title: 'Invoice Price',
                render: (text) => <Text className='text-gray'>{showPrice(text)}</Text>,
                sorter: (a, b) => a.price - b.price,
                filters: this.props.invoices
                    .map(r => { return { text: showPrice(r.price), value: showPrice(r.price) } })
                    .reduce((accum: ColumnFilterItem[], val: ColumnFilterItem) => accum.some(v => v.text === val.text) ? accum : [...accum, val], []),
                filterSearch: true,
                onFilter: (value, record) => {
                    return showPrice(record.price) === value
                },
            },
            {
                key: 'payments',
                dataIndex: 'payments',
                title: 'Total payments',
                render: (text, record) => <Text className='text-gray'>{showPrice(getPaymentsPrice(record.payments))}</Text>,
                sorter: (a, b) => getPaymentsPrice(a.payments) - getPaymentsPrice(b.payments),
                filters: this.props.invoices
                    .map(r => {
                        const price = showPrice(getPaymentsPrice(r.payments))
                        return { text: price, value: price }
                    })
                    .reduce((accum: ColumnFilterItem[], val: ColumnFilterItem) => accum.some(v => v.text === val.text) ? accum : [...accum, val], []),
                filterSearch: true,
                onFilter: (value, record) => showPrice(getPaymentsPrice(record.payments)) === value
            },
            {
                key: 'lastPaymentDate',
                dataIndex: 'lastPaymentDate',
                title: 'Planned payment',
                render: (text) => <Text className='text-gray'>{showDate(new Date(text))}</Text>,
                sorter: (a, b) => new Date(a.lastPaymentDate).getTime() - new Date(b.lastPaymentDate).getTime()
            },
            {
                key: 'createdDate',
                dataIndex: 'createdDate',
                title: 'Created date',
                render: (text) => <Text className='text-gray'>{showDate(new Date(text))}</Text>,
                sorter: (a, b) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime()
            },
            {
                key: 'createdBy',
                dataIndex: 'createdBy',
                title: 'Created By',
                render: (text, record) => <Text className='text-gray'>{record.createdBy.name}</Text>,
                sorter: (a, b) => a.createdBy.name.localeCompare(b.createdBy.name),
                filters: this.props.invoices
                    .map(r => { return { text: r.createdBy.name, value: r.createdBy.name } })
                    .reduce((accum: ColumnFilterItem[], val: ColumnFilterItem) => accum.some(v => v.text === val.text) ? accum : [...accum, val], []),
                filterSearch: true,
                onFilter: (value, record) => record.createdBy.name === value
            },
            {
                key: 'status',
                dataIndex: 'status',
                title: 'Status',
                render: (text, record) => <InvoiceStatus status={this.getStatus(record.price, getPaymentsPrice(record.payments))} />,
                sorter: (a, b) => this.getStatus(a.price, getPaymentsPrice(a.payments)) - this.getStatus(b.price, getPaymentsPrice(b.payments)),
                filters: (Object.values(IPaymentStatus)).filter(s => typeof s !== 'number').map((val) => { return { text: val, value: val } }),
                onFilter: (value, record) => IPaymentStatus[this.getStatus(record.price, getPaymentsPrice(record.payments))] === value
            },
            {
                key: 'downloadIcon',
                dataIndex: 'downloadIcon',
                title: ' ',
                render: (text, record) =>
                    <div className='flex justify-center items-center h-full w-full'>
                        <IconButton iconProps={downloadIcon} onClick={() => this.onDownloadClick(record.id)} />
                    </div>,
            },
        ]

        if (this.props.showFilters)
            columns.splice(1, 0,
                {
                    key: 'customerName',
                    dataIndex: 'customerName',
                    title: 'Customer',
                    render: (text, record) => <Link to={`/customers/${record.customerId}`} className='text-gray'>{text}</Link>,
                    sorter: (a, b) => a.customerName.localeCompare(b.customerName),
                    filters: this.props.invoices
                        .map(r => { return { text: r.customerName, value: r.customerName } })
                        .reduce((accum: ColumnFilterItem[], val: ColumnFilterItem) => accum.some(v => v.text === val.text) ? accum : [...accum, val], []),
                    filterSearch: true,
                    onFilter: (value, record) => {
                        return record.customerName === value
                    },
                    width: '10%',
                },
                {
                    key: 'projectName',
                    dataIndex: 'projectName',
                    title: 'Project',
                    render: (text, record) => <Link to={`/projects/${record.projectId}`} className='text-gray'>{text}</Link>,
                    sorter: (a, b) => a.projectName.localeCompare(b.projectName),
                    filters: this.props.invoices
                        .map(r => { return { text: r.projectName, value: r.projectName } })
                        .reduce((accum: ColumnFilterItem[], val: ColumnFilterItem) => accum.some(v => v.text === val.text) ? accum : [...accum, val], []),
                    filterSearch: true,
                    onFilter: (value, record) => {
                        return record.projectName === value
                    },
                    width: '10%'
                }
            );

        this.setState({ columns })
    }



    onInvoiceClick = (invoice: IInvoiceDto): void => {
        this.props.changeEditFormState();
        this.setState({ editInvoice: invoice });
    }

    onDownloadClick = (id: number): void => {
        this.setState({ loadingModalOpen: true })
        const response = downloadInvoice(id);

        response.then(r => {
            if (r.status === 200) {
                downloadFile(r);
                this.setState({ loadingModalOpen: false })
                Notiflix.Notify.success('Document successfully downloaded')
            }
        })
        response.catch((error: AxiosError) => {
            Notiflix.Notify.failure(`${error.response?.data}`)
            this.setState({ loadingModalOpen: false })
        })
    }

    getStatus = (invoicePrice: number, paymentsPrice: number): IPaymentStatus => {
        return paymentsPrice > invoicePrice ? IPaymentStatus.Overpaid : invoicePrice === paymentsPrice ? IPaymentStatus.Paid : IPaymentStatus.Unpaid
    }

    render(): React.ReactElement<InvoiceList> {
        return (
            <div>
                <div className='flex flex-col gap-4'>
                    <PartName name='Invoices' />

                    <Table
                        rowKey={(record) => record.id}
                        columns={this.state.columns}
                        dataSource={this.props.invoices}
                        pagination={false}
                        showSorterTooltip={false}
                        size='small'
                    />
                </div>

                <RockettaModal
                    closeModal={this.props.changeEditFormState}
                    isOpen={this.props.isEditFormOpen}
                    name={`${this.state.editInvoice?.number}`}
                >
                    <InvoiceEditForm
                        invoice={this.state.editInvoice || {} as IInvoiceDto}
                        closeModal={this.props.changeEditFormState}
                        updateInvoice={this.props.updateInvoice}
                    />
                </RockettaModal>

                <LoaderModal isOpen={this.state.loadingModalOpen} label='Document is loading, please wait' />
            </div>
        );
    }
}

export default InvoiceList;