import * as React from 'react';

import Notiflix from 'notiflix';
import InvoiceList from 'src/components/common/lists/InvoiceList';

import { AxiosError } from 'axios';
import { getAllInvoices } from 'src/api/invoice';
import { IInvoiceDto } from 'src/common/dto';
import { ProgressIndicator } from '@fluentui/react';
import { setDocumentTitle } from 'src/common/functions';

interface InvoicesPageProps {

}

interface InvoicesPageState {
    invoices: IInvoiceDto[];
    invoiceEditFormOpen: boolean;
    loaded: boolean;
}

class InvoicesPage extends React.Component<InvoicesPageProps, InvoicesPageState> {
    constructor(props: InvoicesPageProps) {
        super(props);
        this.state = {
            invoices: [],
            invoiceEditFormOpen: false,
            loaded: false
        };
    }

    componentDidMount(): void {
        setDocumentTitle('Invoices')
        const response = getAllInvoices()

        response.then(r => {
            if (r.status === 200) {
                this.setState({ invoices: r.data, loaded: true })
            }
        })
        response.catch((error: AxiosError) => {
            Notiflix.Notify.failure(error.message);
        })
    }

    changeEditFormState = (): void => {
        this.setState({ invoiceEditFormOpen: !this.state.invoiceEditFormOpen })
    }

    updateInvoice = (invoice: IInvoiceDto): void => {
        const invoices = this.state.invoices;
        const changedInvoice = invoices?.filter(i => i.id === invoice.id)[0];
        if (changedInvoice) {
            const index = invoices.indexOf(changedInvoice);
            invoices[index] = invoice;
        }

        this.setState({ invoices })
    }

    render(): React.ReactElement<InvoicesPage> {
        return this.state.loaded ? (
            <div className='p-5'>
                <InvoiceList
                    invoices={this.state.invoices}
                    changeEditFormState={this.changeEditFormState}
                    showFilters
                    isEditFormOpen={this.state.invoiceEditFormOpen}
                    updateInvoice={this.updateInvoice}
                />
            </div>
        ) : <ProgressIndicator className='mt-m-056' />;
    }
}

export default InvoicesPage;