import * as React from 'react';

import PaymentForm from './PaymentForm';
import Notiflix from 'notiflix';

import { DetailsList, DetailsListLayoutMode, IconButton, SelectionMode } from '@fluentui/react';
import { IInvoiceDto } from 'src/common/dto';
import { showPrice } from 'src/common/functions';
import { deleteButton } from 'src/common/button-style';
import { deleteIcon } from 'src/common/icons';
import { deletePayment } from 'src/api/payment';
import { AxiosError } from 'axios';

interface InvoiceEditFormProps {
    invoice: IInvoiceDto;
    closeModal: () => void;
    updateInvoice: (invoice: IInvoiceDto) => void
}

interface InvoiceEditFormState {
}

class InvoiceEditForm extends React.Component<InvoiceEditFormProps, InvoiceEditFormState> {
    constructor(props: InvoiceEditFormProps) {
        super(props);
        this.state = {};
    }

    onDeletePaymentClick = (invoice: IInvoiceDto, paymentId: number): void => {
        const response = deletePayment(paymentId);

        response.then(r => {
            if (r.status === 200) {
                Notiflix.Notify.success('Payment successfully deleted');
                invoice.payments = invoice.payments.filter(p => p.id !== paymentId);
                this.props.updateInvoice(invoice);
            }
        })
        response.catch((error: AxiosError) => {
            Notiflix.Notify.failure(`${error.response?.data}`)
        })
    }

    render(): React.ReactElement<InvoiceEditForm> {
        return (
            <div>
                <div className='flex gap-4 divide-x'>
                    <div className='h-60 overflow-auto pr-2 divide-y'>
                        <DetailsList
                            items={this.props.invoice.payments.map(p => {
                                return {
                                    id: p.id,
                                    paidDate: new Date(p.date).toLocaleDateString("en-US"),
                                    paid: showPrice(p.price),
                                    deleteIcon:
                                        <IconButton
                                            iconProps={deleteIcon}
                                            className="h-fit"
                                            theme={deleteButton}
                                            onClick={() => { this.onDeletePaymentClick(this.props.invoice, p.id) }}
                                        />
                                }
                            })}
                            columns={[
                                { key: '0', minWidth: 100, name: 'Paid date', fieldName: 'paidDate' },
                                { key: '1', minWidth: 100, name: 'Price', fieldName: 'paid' },
                                { key: '2', minWidth: 10, name: '', fieldName: 'deleteIcon' },
                            ]}
                            selectionMode={SelectionMode.none}
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={true}
                            className='header-p-0'
                        />
                    </div>
                    <div className='pl-6 h-60'>
                        <PaymentForm
                            invoice={this.props.invoice}
                            updateInvoice={this.props.updateInvoice}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default InvoiceEditForm;