import { createTheme } from "@fluentui/react";

export const buttonColor = (disabled: boolean): string => {
    return disabled ? 'disabled-button' : 'primary-button' 
}

export const deleteButton = createTheme({
  palette: {
    themePrimary: '#dc2626',
    themeLighterAlt: '#fef5f5',
    themeLighter: '#f9d8d8',
    themeLight: '#f4b7b7',
    themeTertiary: '#ea7575',
    themeSecondary: '#e03c3c',
    themeDarkAlt: '#c52222',
    themeDark: '#a71c1c',
    themeDarker: '#7b1515',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralSecondaryAlt: '#8a8886',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#ffffff',
}});