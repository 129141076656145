import React from 'react';
import CustomersPage from 'src/pages/customerProject/CustomersPage';
import Header from 'src/components/common/header/Header';
import NotFound from './pages/NotFound';
import CustomerPage from './pages/customerProject/CustomerPage';
import ProjectPage from './pages/customerProject/ProjectPage';
import CustomerSettingsPage from './pages/customerProject/CustomerSettingsPage';
import ProjectSettingsPage from './pages/customerProject/ProjectSettingsPage';
import InvoicesPage from './pages/customerProject/InvoicesPage';
import QuotationsPage from './pages/customerProject/QuotationsPage';
import axios from 'axios';
import NavMenu from './components/common/navMenu/NavMenu';

import { Routes, Route, Navigate } from "react-router-dom";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { loginApiRequest, msalConfig } from './components/common/msal/authConfig';
import { SignInModal } from './components/common/modals/SignInModal';
import { isDevelopment } from './common/functions';
import { DEVELOPMENT_BACK_URL, PRODUCTION_BACK_URL } from './common/constants';
import ProjectsPage from './pages/customerProject/ProjectsPage';

export const App: React.FunctionComponent = () => {
  const msalInstance = new PublicClientApplication(msalConfig);

  axios.defaults.baseURL = isDevelopment(window.location.href) ? DEVELOPMENT_BACK_URL : PRODUCTION_BACK_URL;

  axios.interceptors.request.use(
    async (request) => {
      const account = msalInstance.getAllAccounts()[0];
      const msalResponse = await msalInstance.acquireTokenSilent({
        ...loginApiRequest,
        account: account,
      });

      if (request.headers)
        request.headers.Authorization = `Bearer ${msalResponse.accessToken}`;

      return request;
    }
  );

  return (
    <MsalProvider instance={msalInstance}>
      <AuthenticatedTemplate>
        <Header instance={msalInstance} />
        <div className='h-body bg-main'>
          <div className='flex divide-x'>
            <NavMenu />
            <div className='w-body'>
              <Routes>
                <Route path="/" element={<Navigate replace to='/customers' />} />
                <Route path="/customers" element={<CustomersPage />} />
                <Route path="/customers/:id" element={<CustomerPage />} />
                <Route path="/customers/:id/settings" element={<CustomerSettingsPage />} />
                <Route path="/projects" element={<ProjectsPage />} />
                <Route path="/projects/:id" element={<ProjectPage />} />
                <Route path="/projects/:id/settings" element={<ProjectSettingsPage />} />
                <Route path="/invoices/" element={<InvoicesPage />} />
                <Route path="/quotations/" element={<QuotationsPage />} />
                <Route path="/notFound" element={<NotFound />} />
                <Route path="/*" element={<Navigate replace to='/notFound' />} />
              </Routes>
            </div>
          </div>
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <SignInModal />
      </UnauthenticatedTemplate>
    </MsalProvider>
  );
};