import * as React from 'react';

import AddProjectForm from '../../forms/ProjectForm';
import PartName from '../common/PartName';
import RockettaModal from '../common/modals/RockettaModal';
import Notiflix from 'notiflix';

import { CommandBarButton } from '@fluentui/react';
import { ICustomerDto, Language } from 'src/common/dto';
import { addIcon } from 'src/common/icons';
import { addProject, IProjectPostRequest } from 'src/api/project';
import { AxiosError } from 'axios';
import LoaderModal from '../common/modals/LoaderModal';
import ProjectsList from '../common/lists/ProjectsList';

interface ProjectsProps {
    customer: ICustomerDto;
    setCustomerState: (customer: ICustomerDto) => void
}

interface ProjectsState {
    isOpenAddProjectModal: boolean
    loadingModalOpen: boolean
}

class Projects extends React.Component<ProjectsProps, ProjectsState> {
    constructor(props: ProjectsProps) {
        super(props);
        this.state = { isOpenAddProjectModal: false, loadingModalOpen: false }
    }

    changeAddModalState = (): void => {
        this.setState({ isOpenAddProjectModal: !this.state.isOpenAddProjectModal });
    }

    addProject = (project: IProjectPostRequest, language: Language): void => {
        this.setState({ loadingModalOpen: true, isOpenAddProjectModal: false })
        const response = addProject(project, language);

        response.then((response) => {
            if (response.status === 201) {
                const newProject = response.data
                const newCustomer = this.props.customer;
                newCustomer.projects.unshift(newProject);
                Notiflix.Notify.success('Project successfully added');
                this.props.setCustomerState(newCustomer);
            }
        })
        response.catch((error: AxiosError) => {
            Notiflix.Notify.failure(`${error.response?.data}`)
        })

        this.setState({ loadingModalOpen: false })
    }

    render(): React.ReactElement<Projects> {
        return this.props.customer.projects ? (
            <div className='flex flex-col gap-2'>
                <PartName name='Projects' />
                <div>
                    <CommandBarButton
                        iconProps={addIcon}
                        text="New project"
                        className='h-11'
                        onClick={this.changeAddModalState}
                    />
                </div>
                {this.props.customer.projects.length !== 0 ?
                    <ProjectsList
                        projects={this.props.customer.projects}
                    />
                    : null
                }

                <RockettaModal
                    isOpen={this.state.isOpenAddProjectModal}
                    closeModal={this.changeAddModalState}
                    name='New project'
                >
                    <AddProjectForm
                        buttonText='Add'
                        addMode={{
                            addProject: this.addProject,
                            customer: this.props.customer
                        }}

                    />
                </RockettaModal>

                <LoaderModal isOpen={this.state.loadingModalOpen} label='Project is creating, please wait' />
            </div>
        ) : <></>;
    }
}

export default Projects;