import * as React from 'react';

import { Persona } from '@fluentui/react';
import { Link } from 'react-router-dom';
import { IProjectDto } from 'src/common/dto';

interface ProjectProps {
    project: IProjectDto
    className?: string
}

interface ProjectState {

}

class Project extends React.Component<ProjectProps, ProjectState> {
    constructor(props: ProjectProps) {
        super(props);
    }
    render(): React.ReactElement<Project> {
        const url = '/projects/' + this.props.project.id;

        return (
            <Link to={url}>
                <div className={this.props.className + ' flex justify-between'}>
                    <Persona
                        className='coin-container'
                        text={this.props.project.name}
                    />
                </div>
            </Link>
        );
    }
}

export default Project;