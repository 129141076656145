import * as React from 'react';

import logo from 'src/dist/rocketta-logo.png'

import { Link } from 'react-router-dom';
import { Callout, CommandBarButton, FocusTrapZone, Persona, PersonaInitialsColor, PersonaSize, SearchBox } from '@fluentui/react';
import { IPublicClientApplication } from '@azure/msal-browser';

interface HeaderProps {
    instance: IPublicClientApplication;
}

interface HeaderState {
    isModalOpen: boolean;
}

class Header extends React.Component<HeaderProps, HeaderState> {
    private buttonRef: React.RefObject<HTMLButtonElement>;
    constructor(props: HeaderProps) {
        super(props);
        this.state = { isModalOpen: false }
        this.buttonRef = React.createRef<HTMLButtonElement>();
    }

    changeModalState = (): void => {
        this.setState({ isModalOpen: !this.state.isModalOpen })
    }

    onSignOutClick = (): void => {
        this.props.instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
    };
    
    render(): React.ReactElement<Header> {
        const account = this.props.instance.getAllAccounts()[0];

        return (
            <div>
                <div className='h-12 flex justify-between border-b'>
                    <Link to="/"><img src={logo} className='h-2/3 ml-2 mt-2' alt='Rocketta.De'></img></Link>

                    <div className='flex flex-row w-fit'>
                        <SearchBox
                            disabled
                            className='w-52 mt-2 mr-2'
                            placeholder="Search"
                            maxLength={40}
                        />
                        <button ref={this.buttonRef} onClick={this.changeModalState}>
                            <Persona
                                className='mr-2'
                                initialsColor={PersonaInitialsColor.violet}
                                size={PersonaSize.size32}
                                imageInitials={(account.name || '')[0]}
                                hidePersonaDetails
                                onClick={this.changeModalState}
                            />
                        </button>
                    </div>
                </div>
                {this.state.isModalOpen &&
                    <Callout
                        className='mr-4'
                        isBeakVisible={false}
                        gapSpace={0}
                        doNotLayer={false}
                        target={this.buttonRef}
                        onDismiss={this.changeModalState}
                        setInitialFocus
                    >
                        <FocusTrapZone isClickableOutsideFocusTrap>
                            <div className='w-80 h-fit'>
                                <Persona
                                    className='m-5'
                                    initialsColor={PersonaInitialsColor.violet}
                                    size={PersonaSize.size56}
                                    imageInitials={(account.name || '')[0]}
                                    text={account.name}
                                    secondaryText={account.username}
                                />
                                <div className='flex justify-end'>
                                    <CommandBarButton
                                        text="Sign out"
                                        className='h-11'
                                        onClick={this.onSignOutClick}
                                    />
                                </div>
                            </div>
                        </FocusTrapZone>
                    </Callout>
                }
            </div>
        );
    }
}

export default Header;