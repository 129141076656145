import * as React from 'react';

import PartName from '../PartName';

import { IconButton, Text } from '@fluentui/react';
import { IQuotationDto } from 'src/common/dto';
import { downloadFile, showDate, showPrice } from 'src/common/functions';
import { downloadIcon } from 'src/common/icons';
import { downloadQuotation } from 'src/api/quotation';
import LoaderModal from '../modals/LoaderModal';
import { AxiosError } from 'axios';
import Notiflix from 'notiflix';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ColumnFilterItem } from 'antd/es/table/interface';
import { Link } from 'react-router-dom';

interface QuotationListProps {
    quotations: IQuotationDto[]
    showFilters?: true
}

interface QuotationListState {
    columns: ColumnsType<IQuotationDto>;
    loadingModalOpen: boolean;
}

class QuotationList extends React.Component<QuotationListProps, QuotationListState> {
    constructor(props: QuotationListProps) {
        super(props);
        this.state = {
            columns: [],
            loadingModalOpen: false
        };
    }

    componentDidMount(): void {
        const columns: ColumnsType<IQuotationDto> = [
            {
                key: 'number',
                dataIndex: 'number',
                title: 'Number',
                sorter: (a, b) => a.number.localeCompare(b.number),
                filters: this.props.quotations
                    .map(r => { return { text: r.number, value: r.number } })
                    .reduce((accum: ColumnFilterItem[], val: ColumnFilterItem) => accum.some(v => v.text === val.text) ? accum : [...accum, val], []),
                filterSearch: true,
                onFilter: (value, record) => {
                    return record.number === value
                },
            },
            {
                key: 'price',
                dataIndex: 'price',
                title: 'Quotation Price',
                render: (text) => <Text className='text-gray'>{showPrice(text)}</Text>,
                sorter: (a, b) => a.price - b.price,
                filters: this.props.quotations
                    .map(r => { return { text: showPrice(r.price), value: showPrice(r.price) } })
                    .reduce((accum: ColumnFilterItem[], val: ColumnFilterItem) => accum.some(v => v.text === val.text) ? accum : [...accum, val], []),
                filterSearch: true,
                onFilter: (value, record) => {
                    return showPrice(record.price) === value
                },
                width: '15%'
            },
            {
                key: 'createdDate',
                dataIndex: 'createdDate',
                title: 'Created date',
                render: (text) => <Text className='text-gray'>{showDate(new Date(text))}</Text>,
                sorter: (a, b) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime(),
                width: '10%'
            },
            {
                key: 'createdBy',
                dataIndex: 'createdBy',
                title: 'Created By',
                render: (text, record) => <Text className='text-gray'>{record.createdBy.name}</Text>,
                sorter: (a, b) => a.createdBy.name.localeCompare(b.createdBy.name),
                filters: this.props.quotations
                    .map(r => { return { text: r.createdBy.name, value: r.createdBy.name } })
                    .reduce((accum: ColumnFilterItem[], val: ColumnFilterItem) => accum.some(v => v.text === val.text) ? accum : [...accum, val], []),
                filterSearch: true,
                onFilter: (value, record) => record.createdBy.name === value,
                width: '15%'
            },
            {
                key: 'downloadIcon',
                dataIndex: 'downloadIcon',
                title: ' ',
                render: (text, record) =>
                    <div className='flex justify-center items-center h-full w-full'>
                        <IconButton iconProps={downloadIcon} onClick={() => this.onDownloadClick(record.id)} />
                    </div>,
                width: '5%'
            },
        ]

        if (this.props.showFilters)
            columns.splice(1, 0,
                {
                    key: 'customerName',
                    dataIndex: 'customerName',
                    title: 'Customer',
                    render: (text, record) => <Link to={`/customers/${record.customerId}`} className='text-gray'>{text}</Link>,
                    sorter: (a, b) => a.customerName.localeCompare(b.customerName),
                    filters: this.props.quotations
                        .map(r => { return { text: r.customerName, value: r.customerName } })
                        .reduce((accum: ColumnFilterItem[], val: ColumnFilterItem) => accum.some(v => v.text === val.text) ? accum : [...accum, val], []),
                    filterSearch: true,
                    onFilter: (value, record) => {
                        return record.customerName === value
                    },
                },
                {
                    key: 'projectName',
                    dataIndex: 'projectName',
                    title: 'Project',
                    render: (text, record) => <Link to={`/projects/${record.projectId}`} className='text-gray'>{text}</Link>,
                    sorter: (a, b) => a.projectName.localeCompare(b.projectName),
                    filters: this.props.quotations
                        .map(r => { return { text: r.projectName, value: r.projectName } })
                        .reduce((accum: ColumnFilterItem[], val: ColumnFilterItem) => accum.some(v => v.text === val.text) ? accum : [...accum, val], []),
                    filterSearch: true,
                    onFilter: (value, record) => {
                        return record.projectName === value
                    },
                }
            );

        this.setState({ columns })
    }

    onDownloadClick = (id: number): void => {
        this.setState({ loadingModalOpen: true })
        const response = downloadQuotation(id);

        response.then(r => {
            if (r.status === 200) {
                downloadFile(r);
                this.setState({ loadingModalOpen: false })
                Notiflix.Notify.success('Document successfully downloaded')
            }
        })
        response.catch((error: AxiosError) => {
            Notiflix.Notify.failure(`${error.response?.data}`)
            this.setState({ loadingModalOpen: false })
        })
    }


    render(): React.ReactElement<QuotationList> {
        return (
            <div className='flex flex-col gap-4'>
                <PartName name='Quotations' />

                <Table
                    rowKey={(record) => record.id}
                    columns={this.state.columns}
                    dataSource={this.props.quotations}
                    pagination={false}
                    showSorterTooltip={false}
                    size='small'
                />

                <LoaderModal isOpen={this.state.loadingModalOpen} label='Document is loading, please wait'></LoaderModal>
            </div>
        );
    }
}

export default QuotationList;