import * as React from 'react';

import Project from '../common/entities/Project';
import Table from '../common/table/Table';
import PartName from '../common/PartName';
import TableSettingElement from '../common/table/TableSettingElement';
import ProjectForm from 'src/forms/ProjectForm';
import RockettaModal from '../common/modals/RockettaModal';
import Notiflix from 'notiflix';

import { ICustomerDto, IProjectDto, Language } from 'src/common/dto';
import { PrimaryButton } from '@fluentui/react';
import { addProject, deleteProject, IProjectPostRequest, IProjectPutRequest, updateProject } from 'src/api/project';
import { AxiosError } from 'axios';
import LoaderModal from '../common/modals/LoaderModal';

interface ProjectsProps {
    customer: ICustomerDto;
    setCustomerState: (customer: ICustomerDto) => void
}

interface ProjectsState {
    isAddModalOpen: boolean;
    isEditModalOpen: boolean;
    EditProjectId: number;
    loadingModalOpen: boolean;
}

class Projects extends React.Component<ProjectsProps, ProjectsState> {
    constructor(props: ProjectsProps) {
        super(props);
        this.state = { isAddModalOpen: false, isEditModalOpen: false, EditProjectId: 0, loadingModalOpen: false }
    }

    changeAddModalState = (): void => {
        this.setState({ isAddModalOpen: !this.state.isAddModalOpen })
    }

    changeEditModalState = (): void => {
        this.setState({ isEditModalOpen: !this.state.isEditModalOpen })
    }

    editClick = (id: number): void => {
        this.setState({ EditProjectId: id })
        this.changeEditModalState();
    }

    deleteProject = (id: number): void => {
        this.setState({ loadingModalOpen: true })
        const response = deleteProject(id);

        response.then((response) => {
            if (response.status === 200) {
                const project = this.props.customer.projects.find(p => p.id === id)

                if (project !== undefined) {
                    const index = this.props.customer.projects.indexOf(project)
                    const newCustomer = this.props.customer;
                    newCustomer.projects.splice(index, 1);
                    Notiflix.Notify.success('Project successfully deleted');
                    this.props.setCustomerState(newCustomer);
                }
            }
        })
        response.catch((error: AxiosError) => {
            Notiflix.Notify.failure(`${error.response?.data}`)
        })

        this.setState({ loadingModalOpen: false })
    }

    addProject = (project: IProjectPostRequest, language: Language): void => {
        this.setState({ loadingModalOpen: true })
        const response = addProject(project, language);

        response.then((response) => {
            if (response.status === 201) {
                const newProject = response.data
                const newCustomer = this.props.customer;
                newCustomer.projects.unshift(newProject);
                Notiflix.Notify.success('Project successfully added');
                this.props.setCustomerState(newCustomer);
                this.changeAddModalState();
            }
        })
        response.catch((error: AxiosError) => {
            Notiflix.Notify.failure(`${error.response?.data}`)
        })


        this.setState({ loadingModalOpen: false })
    }

    updateProject = (id: number, project: IProjectPutRequest, language: Language): void => {
        this.setState({ loadingModalOpen: true })
        const response = updateProject(id, project, language);

        response.then((response) => {
            if (response.status === 200) {
                const project = this.props.customer.projects.find(p => p.id === id)
                if (project !== undefined) {
                    const newCustomer = this.props.customer;
                    const index = this.props.customer.projects.indexOf(project)
                    const newProject = response.data
                    newCustomer.projects[index] = newProject;
                    Notiflix.Notify.success('Project successfully updated');
                    this.props.setCustomerState(newCustomer);
                }

                this.changeEditModalState();
            }
        })
        response.catch((error: AxiosError) => {
            Notiflix.Notify.failure(`${error.response?.data}`)
        })


        this.setState({ loadingModalOpen: false })
    }

    render(): React.ReactElement<Projects> {
        return (
            <div>
                <div className='flex flex-col gap-4'>
                    <PartName name='Projects' />

                    {this.props.customer?.projects?.length > 0 ?
                        <Table className='min-w-half max-w-fit'>
                            {this.props.customer.projects.map(p =>
                                <TableSettingElement
                                    key={p.id}
                                    itemName={p.name}
                                    onDeleteClick={this.deleteProject}
                                    elementId={p.id}
                                    onEditClick={this.editClick}
                                    isImportantDeleting={true}
                                >
                                    <Project
                                        project={p}
                                    />
                                </TableSettingElement>
                            )}
                        </Table>
                        : null
                    }
                    <div>
                        <PrimaryButton
                            className='mt-2 primary-button'
                            text='Add project'
                            onClick={this.changeAddModalState}
                        />
                    </div>
                </div>

                <RockettaModal
                    isOpen={this.state.isAddModalOpen}
                    closeModal={this.changeAddModalState}
                    name='Add project'
                >
                    <ProjectForm
                        buttonText='Add'
                        addMode={{
                            customer: this.props.customer,
                            addProject: this.addProject
                        }}
                    />
                </RockettaModal>

                <RockettaModal
                    isOpen={this.state.isEditModalOpen}
                    closeModal={this.changeEditModalState}
                    name='Edit project'
                >
                    <ProjectForm
                        buttonText='Save'
                        editMode={{
                            project: this.props.customer.projects
                                .find(p => p.id === this.state.EditProjectId) || {} as IProjectDto,
                            editProject: this.updateProject
                        }}
                    />
                </RockettaModal>

                <LoaderModal isOpen={this.state.loadingModalOpen} label='Please wait' />
            </div>
        );
    }
}

export default Projects;