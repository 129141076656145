import * as React from 'react';

import Notiflix from 'notiflix';

import { DatePicker, Dropdown, IDropdownOption, PrimaryButton, TextField } from '@fluentui/react';
import { addPayment, IPaymentPostDto } from 'src/api/payment';
import { MAX_PRICE } from 'src/common/constants';
import { IInvoiceDto } from 'src/common/dto';
import { AxiosError } from 'axios';

interface PaymentFormProps {
    invoice?: IInvoiceDto;
    invoices?: IInvoiceDto[];
    closeModal?: () => void;
    updateInvoice: (invoice: IInvoiceDto) => void
}

interface PaymentFormState {
    payment: IPaymentPostDto;
    shownPrice: string;
}

class PaymentForm extends React.Component<PaymentFormProps, PaymentFormState> {
    private _invoiceOptions: IDropdownOption[] = [];

    constructor(props: PaymentFormProps) {
        super(props);
        this.state = { shownPrice: '0', payment: { date: new Date(), price: 0, invoiceId: this.props.invoice ? this.props.invoice.id : 0 } };
    }

    componentDidMount(): void {
        this.props.invoices?.forEach(i => this._invoiceOptions.push({ key: i.id, text: i.number }));
    }

    changeInvoiceNumber = (event: React.FormEvent<HTMLDivElement>, option: IDropdownOption | undefined): void => {
        const payment = this.state.payment;
        payment.invoiceId = Number(option?.key || 0);
        this.setState({ payment })
    }

    changePrice = (event: React.SyntheticEvent<HTMLElement, Event>, newValue?: string | undefined): void => {
        if (newValue !== undefined) {
            newValue = newValue.replace(',', '.').replaceAll(' ', '')

            if (!isNaN(Number(newValue)) && newValue.length <= Number(newValue).toFixed(2).length && Number(newValue) < MAX_PRICE && Number(newValue) >= 0) {
                if (newValue.length > 1 && newValue[0] === '0' && newValue[1] !== '.') {
                    newValue = newValue.substring(1, newValue.length);
                }

                const payment = this.state.payment;
                payment.price = Number(newValue);
                const shownPrice = newValue.replace('.', ',');
                this.setState({ payment: payment, shownPrice: shownPrice });
            }
        }
    }

    onPriceFocus = (): void => {
        const shownPrice = this.state.shownPrice.replaceAll('.', '');
        this.setState({ shownPrice: shownPrice })
    }

    onPriceBlur = (): void => {
        const shownPrice = this.state.payment.price.toLocaleString("De-de");
        this.setState({ shownPrice: shownPrice })
    }

    onDateChange = (value: Date | undefined | null): void => {
        if (value) {
            const payment = this.state.payment;
            payment.date = value;
            this.setState({ payment });
        }
    }

    onCreateClick = (): void => {
        const payment = this.state.payment;
        const response = addPayment(payment);
        response.then(r => {
            if (r.status === 201) {
                Notiflix.Notify.success('Payment successfully added');
                const invoice = this.props.invoices?.filter(i => i.id === payment.invoiceId)[0] || this.props.invoice;
                invoice?.payments.push(r.data);

                if (invoice)
                    this.props.updateInvoice(invoice)

                if (this.props.closeModal)
                    this.props.closeModal();
                else {
                    payment.price = 0;
                    this.setState({ payment, shownPrice: '0' })
                }
            }
        });
        response.catch((error: AxiosError) => {
            Notiflix.Notify.failure(`${error.response?.data}`)
        })
    }

    isButtonDisable = (): boolean => {
        return this.state.payment.invoiceId === 0 || this.state.payment.price === 0;
    }

    render(): React.ReactElement<PaymentForm> {
        return (
            <div>
                <div className='w-56 flex flex-col gap-4'>
                    {!this.props.invoice &&
                        <Dropdown
                            label='Invoice number'
                            options={this._invoiceOptions}
                            onChange={this.changeInvoiceNumber}
                        />
                    }

                    <TextField
                        label='Price'
                        suffix='€'
                        value={this.state.shownPrice}
                        onChange={this.changePrice}
                        onBlur={this.onPriceBlur}
                        onFocus={this.onPriceFocus}
                    />

                    <DatePicker
                        label='Date'
                        value={this.state.payment.date}
                        onSelectDate={this.onDateChange}
                    />
                </div>

                <div className='mt-6'>
                    <PrimaryButton
                        text='Add'
                        disabled={this.isButtonDisable()}
                        className={this.isButtonDisable() ? 'disabled-button' : 'primary-button'}
                        onClick={this.onCreateClick}
                    />
                </div>
            </div>
        );
    }
}

export default PaymentForm;