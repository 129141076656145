import * as React from 'react';

import Overview from 'src/components/common/Overview';
import ModuleTable from 'src/components/projectPage/ModuleTable';
import RockettaModal from 'src/components/common/modals/RockettaModal';
import Notiflix from 'notiflix';
import QuotationForm from 'src/forms/documentForms/QuotationForm';
import InvoiceFactoryForm from 'src/forms/documentForms/InvoiceFactoryForm';
import PaymentForm from 'src/forms/PaymentForm';
import InvoiceList from 'src/components/common/lists/InvoiceList';
import QuotationList from 'src/components/common/lists/QuotationList';

import { PrimaryButton, ProgressIndicator } from '@fluentui/react';
import { Navigate } from 'react-router-dom';
import { getProject } from 'src/api/project';
import { IInvoiceDto, IProjectDto, ModuleType } from 'src/common/dto';
import { AxiosError } from 'axios';
import { setDocumentTitle } from 'src/common/functions';

interface ProjectPageProps {

}

interface ProjectPageState {
    project: IProjectDto;
    isNotFound: boolean;
    quotationFormOpen: boolean;
    invoiceFormOpen: boolean;
    paymentFormOpen: boolean;
    invoiceEditFormOpen: boolean;
    loaded: boolean;
}


class ProjectPage extends React.Component<ProjectPageProps, ProjectPageState> {
    constructor(props: ProjectPageProps) {
        super(props);
        this.state = {
            project: {} as IProjectDto,
            isNotFound: false,
            quotationFormOpen: false,
            invoiceFormOpen: false,
            paymentFormOpen: false,
            invoiceEditFormOpen: false,
            loaded: false
        }
    }

    getProject = (id: number): void => {
        const response = getProject(id)

        response.then(r => {
            if (r.status === 200) {
                const project: IProjectDto = r.data;
                setDocumentTitle(project.name)
                this.setProject(project)
                this.setState({ loaded: true })
            }
        })
        response.catch((error: AxiosError) => {
            if (error.response?.status === 404)
                this.setState({ isNotFound: true })
            else
                Notiflix.Notify.failure(error.message);
        })
    }

    componentDidMount = (): void => {
        const path = window.location.pathname;
        const directories = path.split("/");
        const id = directories[(directories.length - 1)];
        this.getProject(parseInt(id));
    }

    changeQuotationFormState = (): void => {
        this.setState({ quotationFormOpen: !this.state.quotationFormOpen })
    }

    changeInvoiceFormState = (): void => {
        this.setState({ invoiceFormOpen: !this.state.invoiceFormOpen })
    }

    changePaymentFormState = (): void => {
        this.setState({ paymentFormOpen: !this.state.paymentFormOpen })
    }

    setProject = (project: IProjectDto): void => {
        const modules = project.modules.filter(m => m.type === ModuleType[ModuleType.Module]).sort((a, b) => a.sortId - b.sortId);
        const otherModules = project.modules.filter(m => m.type !== ModuleType[ModuleType.Module]).sort((a, b) => a.sortId - b.sortId);
        project.modules = modules.concat(otherModules);
        this.setState({ project: project })
    }

    changeEditFormState = (): void => {
        this.setState({ invoiceEditFormOpen: !this.state.invoiceEditFormOpen })
    }

    updateInvoice = (invoice: IInvoiceDto): void => {
        const project = this.state.project;
        const invoices = project?.invoices;
        const changedInvoice = invoices?.filter(i => i.id === invoice.id)[0];
        if (changedInvoice) {
            const index = invoices.indexOf(changedInvoice);
            invoices[index] = invoice;
        }

        this.setState({ project })
    }

    render(): React.ReactElement<ProjectPage> {
        return this.state.loaded ? (
            <div>
                <Overview
                    name={this.state.project.name}
                    secondName={this.state.project.customerName}
                    url={`/customers/${this.state.project.customerId}`}
                    status={this.state.project.status}
                />
                <div className='p-5 flex flex-col gap-4'>
                    <ModuleTable
                        project={this.state.project}
                        setProject={this.setProject}
                    />
                    {this.state.project.invoices?.length !== 0 &&
                        <InvoiceList
                            invoices={this.state.project.invoices}
                            changeEditFormState={this.changeEditFormState}
                            isEditFormOpen={this.state.invoiceEditFormOpen}
                            updateInvoice={this.updateInvoice}
                        />
                    }

                    {this.state.project.quotations?.length !== 0 &&
                        <QuotationList
                            quotations={this.state.project.quotations}
                        />
                    }

                    <div className='flex gap-4'>
                        <PrimaryButton
                            text='Create quotation'
                            className='primary-button'
                            onClick={this.changeQuotationFormState}
                        />
                        <PrimaryButton
                            text='Create invoice'
                            className='primary-button'
                            onClick={this.changeInvoiceFormState}
                        />
                        <PrimaryButton
                            text='Add payment'
                            className='primary-button'
                            onClick={this.changePaymentFormState}
                        />
                    </div>

                    <RockettaModal
                        closeModal={this.changeQuotationFormState}
                        isOpen={this.state.quotationFormOpen}
                    >
                        <QuotationForm
                            project={JSON.parse(JSON.stringify(this.state.project))}
                            closeModal={this.changeQuotationFormState}
                            setProject={this.setProject}
                        />
                    </RockettaModal>

                    <RockettaModal
                        closeModal={this.changeInvoiceFormState}
                        isOpen={this.state.invoiceFormOpen}
                    >
                        <InvoiceFactoryForm
                            project={JSON.parse(JSON.stringify(this.state.project))}
                            closeModal={this.changeInvoiceFormState}
                            setProject={this.setProject}
                        />
                    </RockettaModal>

                    <RockettaModal
                        closeModal={this.changePaymentFormState}
                        isOpen={this.state.paymentFormOpen}
                        name='Payment form'
                    >
                        <PaymentForm
                            invoices={this.state.project.invoices}
                            closeModal={this.changePaymentFormState}
                            updateInvoice={this.updateInvoice}
                        />
                    </RockettaModal>
                </div>
            </div>
        ) : this.state.isNotFound ? <Navigate to='/notFound' replace={true} /> : <ProgressIndicator className='mt-m-056' />
    }
}

export default ProjectPage;