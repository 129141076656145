import * as React from 'react';

import Table from '../common/table/Table';
import TableElement from '../common/table/TableElement';
import ModuleForm from '../../forms/ModuleForm';
import Notiflix from 'notiflix';
import PartName from '../common/PartName';
import Line from '../common/Line';
import RockettaModal from '../common/modals/RockettaModal';
import OpenModuleForm from 'src/forms/OpenModuleForm';
import LoaderModal from '../common/modals/LoaderModal';

import { Checkbox, CommandBarButton } from '@fluentui/react';
import { addModule, deleteModule, IModulePostDto, IModulePutDto, IModuleSortPatchDto, updateModule, updateModules } from 'src/api/module';
import { IModuleDto, IProjectDto, ModuleType } from 'src/common/dto';
import { addIcon } from 'src/common/icons';
import { AxiosError } from 'axios';
import { DAY_TIME_INTERVAL, ENG_TEST_DESCRIPTION, GER_TEST_DESCRIPTION, ENG_MANAGEMENT_DESCRIPTION, GER_MANAGEMENT_DESCRIPTION, TEST_PERCENTAGE, MANAGEMENT_PERCENTAGE, DEFAULT_DESCRIPTION, HOUR_TIME_INTERVAL, WORKING_HOURS_IN_DAY } from 'src/common/constants';
import { calcQtyByPercent, isEnglishLanguage, showPrice } from 'src/common/functions';

interface ModuleTableProps {
    project: IProjectDto;
    setProject: (project: IProjectDto) => void
}

interface ModuleTableState {
    openNewModuleForm: boolean;
    module: IModuleDto;
    loadingModalOpen: boolean;
}

class ModuleTable extends React.Component<ModuleTableProps, ModuleTableState> {
    constructor(props: ModuleTableProps) {
        super(props);
        this.state = {
            module: {} as IModuleDto,
            openNewModuleForm: false,
            loadingModalOpen: false
        }
    }

    updateModuleSort = (modules: IModuleDto[]): void => {
        this.setState({ loadingModalOpen: true })
        const response = updateModules(modules.map(m => {
            return {
                id: m.id,
                sortId: m.sortId,
            } as IModuleSortPatchDto;
        }));

        response.then((response) => {
            if (response.status === 200) {
                const project = this.props.project;
                const modules = response.data as IModuleDto[];
                modules.forEach(m => { project.modules.filter(pm => pm.id === m.id)[0] = m });
                project.modules.sort((a, b) => a.sortId - b.sortId);
                this.props.setProject(project);
                Notiflix.Notify.success('Sort successfully updated');
            }
        })
        response.catch((error: AxiosError) => {
            Notiflix.Notify.failure(`${error.response?.data}`)
        })

        this.setState({ loadingModalOpen: false })
    }

    addModule = (module: IModulePostDto): void => {
        this.setState({ loadingModalOpen: true, openNewModuleForm: false })
        const response = addModule(module);

        response.then(r => {
            if (r.status === 201) {
                const project = this.props.project;
                project.modules.push(r.data);
                this.props.setProject(project)
                Notiflix.Notify.success('Position successfully added');
            }
        })
        response.catch((error: AxiosError) => {
            Notiflix.Notify.failure(`${error.response?.data}`)
        })

        this.setState({ loadingModalOpen: false })
    }

    updateModule = (id: number, module: IModulePutDto): void => {
        this.setState({ loadingModalOpen: true })
        const response = updateModule(id, module);

        response.then((response) => {
            if (response.status === 200) {
                const project = this.props.project;
                const index = project.modules.findIndex(x => x.id === id);
                project.modules[index] = response.data;
                this.props.setProject(project);
                Notiflix.Notify.success('Position successfully updated');
            }
        })
        response.catch((error: AxiosError) => {
            Notiflix.Notify.failure(`${error.response?.data}`)
        })

        this.setState({ loadingModalOpen: false })
    }

    deleteModule = (id: number): void => {
        this.setState({ loadingModalOpen: true })
        const response = deleteModule(id);

        response.then((response) => {
            if (response.status === 200) {
                const project = this.props.project;
                project.modules = project.modules.filter((module) => module.id !== id)
                this.props.setProject(project);
                Notiflix.Notify.success('Position successfully deleted');
            }
        })
        response.catch((error: AxiosError) => {
            Notiflix.Notify.failure(`${error.response?.data}`)
        })


        this.setState({ loadingModalOpen: false })
    }

    createModule = (): void => {
        const module: IModuleDto = {
            id: 0,
            description: DEFAULT_DESCRIPTION,
            isOptional: false,
            price: Number((this.props.project.defaultPricePerDay / WORKING_HOURS_IN_DAY).toFixed(2)),
            projectId: this.props.project.id,
            quantity: WORKING_HOURS_IN_DAY,
            timeInterval: HOUR_TIME_INTERVAL,
            type: ModuleType[ModuleType.Module],
            sortId: this.props.project.modules.filter(m => m.type === ModuleType[ModuleType.Module]).length,
        }
        this.setState({ module, openNewModuleForm: true })
    }

    createTestingModule = (): void => {
        const module: IModuleDto = {
            id: 0,
            description: isEnglishLanguage(this.props.project.language) ? ENG_TEST_DESCRIPTION : GER_TEST_DESCRIPTION,
            isOptional: false,
            price: this.props.project.defaultPricePerDay,
            projectId: this.props.project.id,
            quantity: calcQtyByPercent(TEST_PERCENTAGE, DAY_TIME_INTERVAL, this.props.project.modules),
            sortId: this.props.project.modules.filter(m => m.type !== ModuleType[ModuleType.Module]).length,
            timeInterval: DAY_TIME_INTERVAL, type: ModuleType[ModuleType.Testing]
        }

        this.setState({ module, openNewModuleForm: true })
    }

    createManagementModule = (): void => {
        const module: IModuleDto = {
            id: 0,
            description: isEnglishLanguage(this.props.project.language) ? ENG_MANAGEMENT_DESCRIPTION : GER_MANAGEMENT_DESCRIPTION,
            isOptional: false,
            price: this.props.project.defaultPricePerDay,
            projectId: this.props.project.id,
            quantity: calcQtyByPercent(MANAGEMENT_PERCENTAGE, DAY_TIME_INTERVAL, this.props.project.modules),
            sortId: this.props.project.modules.filter(m => m.type !== ModuleType[ModuleType.Module]).length,
            timeInterval: DAY_TIME_INTERVAL, type: ModuleType[ModuleType.Management]
        }

        this.setState({ module, openNewModuleForm: true })
    }

    createPercentageModule = (): void => {
        const module: IModuleDto = {
            id: 0,
            description: DEFAULT_DESCRIPTION,
            isOptional: false,
            price: this.props.project.defaultPricePerDay,
            projectId: this.props.project.id,
            quantity: 1,
            timeInterval: DAY_TIME_INTERVAL,
            type: ModuleType[ModuleType.PercentageModule],
            sortId: this.props.project.modules.filter(m => m.type !== ModuleType[ModuleType.Module]).length
        }
        this.setState({ module, openNewModuleForm: true })
    }

    isTestingModuleExist = (): boolean => {
        return this.props.project.modules.filter(m => m.type === ModuleType[ModuleType.Testing]).length === 1;
    }

    changeTestingModuleCheckBox = (ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean): void => {
        if (checked)
            this.createTestingModule();
        else
            this.deleteModule(this.props.project.modules.filter(m => m.type === ModuleType[ModuleType.Testing])[0].id)
    }

    isManagementModuleExist = (): boolean => {
        return this.props.project.modules.filter(m => m.type === ModuleType[ModuleType.Management]).length === 1;
    }

    changeManagementModuleCheckBox = (ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean): void => {
        if (checked)
            this.createManagementModule();
        else
            this.deleteModule(this.props.project.modules.filter(m => m.type === ModuleType[ModuleType.Management])[0].id)
    }

    showTotalModulePrice = (modules: IModuleDto[]): string => {
        let total = 0;
        modules.map(m => { total += m.price * m.quantity })
        return showPrice(total)
    }

    render(): React.ReactElement<ModuleTable> {
        const modules = this.props.project.modules.filter(m => m.type === ModuleType[ModuleType.Module]);
        const percentageModules = this.props.project.modules.filter(m => m.type !== ModuleType[ModuleType.Module]);

        return (
            <div>
                <div className='flex flex-col gap-2'>
                    <PartName name='Positions' />
                    <div>
                        <CommandBarButton
                            iconProps={addIcon}
                            text="New position"
                            className='h-11'
                            onClick={this.createModule}
                        />
                    </div>
                    {modules.length > 0 ?
                        <Table>
                            {modules.map(m =>
                                <TableElement key={m.id}>
                                    <ModuleForm
                                        module={m}
                                        defaultName={'Position #' + (modules.indexOf(m) + 1)}
                                        project={this.props.project}
                                        deleteModule={this.deleteModule}
                                        updateModule={this.updateModule}
                                        updateModuleSort={this.updateModuleSort}
                                    />
                                </TableElement>
                            )}
                        </Table>
                        : null
                    }
                    {
                        modules.length > 0 &&
                        <div className='self-end text-gray-600 mr-1 mt-2'>
                            Total: {this.showTotalModulePrice(modules)}
                        </div>
                    }
                    <Line />

                    <PartName name='Percentage positions' />
                    <div>
                        <CommandBarButton
                            iconProps={addIcon}
                            text="New position"
                            className='h-11'
                            onClick={this.createPercentageModule}
                        />
                    </div>
                    <div className='flex gap-6'>
                        <Checkbox
                            label='Testing'
                            checked={this.isTestingModuleExist()}
                            onChange={this.changeTestingModuleCheckBox}
                        />
                        <Checkbox
                            label='Project management'
                            checked={this.isManagementModuleExist()}
                            onChange={this.changeManagementModuleCheckBox}
                        />
                    </div>
                    {percentageModules.length > 0 ?
                        <Table>
                            {percentageModules.map(m =>
                                <TableElement key={m.id}>
                                    <ModuleForm
                                        defaultName={'Position #' + (percentageModules.indexOf(m) + 1)}
                                        project={this.props.project}
                                        module={m}
                                        deleteModule={this.deleteModule}
                                        updateModule={this.updateModule}
                                        updateModuleSort={this.updateModuleSort}
                                    />
                                </TableElement>
                            )}
                        </Table>
                        : null
                    }
                    {
                        percentageModules.length > 0 &&
                        <div className='self-end text-gray-600 mr-1 mt-2'>
                            Total: {this.showTotalModulePrice(percentageModules)}
                        </div>
                    }
                    <Line />
                </div>

                <RockettaModal
                    isOpen={this.state.openNewModuleForm}
                    name={this.state.module.type === ModuleType[ModuleType.Module] ? 'New position' : 'New percentage position'}
                    closeModal={() => this.setState({ openNewModuleForm: false })}
                >
                    <OpenModuleForm
                        module={this.state.module}
                        project={this.props.project}
                        createModule={this.addModule}
                    />
                </RockettaModal>

                <LoaderModal isOpen={this.state.loadingModalOpen} label='Please wait' />
            </div>
        );
    }
}

export default ModuleTable;