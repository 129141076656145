import * as React from 'react';
import { IPaymentStatus } from './lists/InvoiceList';

interface InvoiceStatusProps {
    status: IPaymentStatus;
}

interface InvoiceStatusState {

}

class InvoiceStatus extends React.Component<InvoiceStatusProps, InvoiceStatusState> {
    constructor(props: InvoiceStatusProps) {
        super(props);
        this.state = {};
    }
    render(): React.ReactElement<InvoiceStatus> {
        const colors = this.props.status === IPaymentStatus.Paid ? 'bg-green-100 text-green-700' : this.props.status === IPaymentStatus.Overpaid ? 'bg-yellow-100 text-yellow-700' : 'bg-red-100 text-red-700';
        
        return (
            <div className={`${colors} text-center pt-1 pb-1 rounded-md`}>
                {IPaymentStatus[this.props.status]}
            </div>
        );
    }
}

export default InvoiceStatus;