import * as React from 'react';

import ContactPerson from '../common/entities/ContactPerson';
import PartName from '../common/PartName';
import Table from '../common/table/Table';
import TableElement from '../common/table/TableElement';

import { ICustomerDto } from 'src/common/dto';

interface ContactDataProps {
    customer: ICustomerDto;
}

interface ContactDataState {
}

class ContactData extends React.Component<ContactDataProps, ContactDataState> {
    constructor(props: ContactDataProps) {
        super(props);
    }

    render(): React.ReactElement<ContactData> {
        return (
            <div className={`flex flex-col gap-6 ${this.props.customer?.address && this.props.customer.contactPeople && 'mb-6'}`}>
                {this.props.customer?.address ?
                    <div className='flex flex-col gap-4'>
                        <PartName name='Address' />
                        <Table className='border-b min-w-30 max-w-fit p-2 text-base text-gray-600'>
                            {
                                [this.props.customer.address.streetHouse, this.props.customer.address.city,
                                this.props.customer.address.country, this.props.customer.address.zipCode,
                                this.props.customer.address.department]
                                    .filter(el => el).join(", ")
                            }
                        </Table>
                    </div>
                    :
                    null
                }

                {this.props.customer?.contactPeople?.length > 0 ?
                    <div className='flex flex-col gap-4'>
                        <PartName name='Contact people' />
                        <Table className='min-w-30 max-w-fit'>
                            {this.props.customer.contactPeople.map(p =>
                                <TableElement className='w-full border-b' key={p.id}>
                                    <ContactPerson
                                        person={p}
                                        className='m-3'
                                    />
                                </TableElement>
                            )
                            }
                        </Table>
                    </div>
                    :
                    null
                }
            </div>

        );
    }
}

export default ContactData;