import * as React from 'react';

import InvoiceForm from './InvoiceForm';
import PartialInvoiceForm from './PartialInvoiceForm';
import FinalInvoiceForm from './FinalInvoiceForm';

import { DatePicker, Dropdown, IDropdownOption } from '@fluentui/react';
import { InvoiceType, IProjectDto } from 'src/common/dto';

interface InvoiceFactoryFormProps {
    project: IProjectDto;
    closeModal: () => void;
    setProject: (project: IProjectDto) => void
}

interface InvoiceFactoryFormState {
    invoiceType: InvoiceType;
    lastPaymentDate: Date;
    partialInvoiceIds: number[],
}

class InvoiceFactoryForm extends React.Component<InvoiceFactoryFormProps, InvoiceFactoryFormState> {
    private _partialInvoices: IDropdownOption[] = [];

    constructor(props: InvoiceFactoryFormProps) {
        super(props);
        this.state = {
            invoiceType: InvoiceType.Invoice,
            lastPaymentDate: new Date(new Date().setDate(new Date().getDate() + 30)),
            partialInvoiceIds: [],
        };
    }

    componentDidMount(): void {
        this.props.project.invoices?.map(i => {
            if (i.type === InvoiceType[InvoiceType.Partial] || i.type === InvoiceType[InvoiceType.PartialModules])
                this._partialInvoices.push({ key: i.id, text: i.number })
        })
    }

    onInvoiceTypeChange = (event: React.FormEvent<HTMLDivElement>, option: IDropdownOption | undefined): void => {
        if (option)
            this.setState({ invoiceType: option.key as number });
    }


    onPartialInvoicesChange = (event: React.FormEvent<HTMLDivElement>, option: IDropdownOption | undefined): void => {
        let partialInvoiceIds = this.state.partialInvoiceIds

        if (option?.selected)
            partialInvoiceIds.push(Number(option.key))
        else
            partialInvoiceIds = partialInvoiceIds.filter(i => i !== Number(option?.key));

        this.setState({ partialInvoiceIds });
    }

    selectDate = (date: Date | null | undefined): void => {
        if (date) {
            this.setState({ lastPaymentDate: date })
        }
    }

    render(): React.ReactElement<InvoiceFactoryForm> {
        return (
            <div className='flex flex-col gap-4'>
                <div className='flex gap-4'>
                    <Dropdown
                        label='Invoice type'
                        className='w-36'
                        defaultSelectedKey={this.state.invoiceType}
                        options={[
                            { key: InvoiceType.Invoice, text: 'Invoice' },
                            { key: InvoiceType.Partial, text: 'Partial' },
                            { key: InvoiceType.PartialModules, text: 'Partial (Positions)' },
                            { key: InvoiceType.Final, text: 'Final' },
                        ]}
                        onChange={this.onInvoiceTypeChange}
                    />

                    <DatePicker
                        label='Planned payment'
                        value={this.state.lastPaymentDate}
                        className='w-36'
                        onSelectDate={this.selectDate}
                    />
                    
                    {InvoiceType[this.state.invoiceType] === InvoiceType[InvoiceType.Final] &&
                        <Dropdown
                            label='Partial invoices'
                            className='min-w-36 max-w-fit'
                            multiSelect
                            options={this._partialInvoices}
                            onChange={this.onPartialInvoicesChange}
                        />
                    }
                </div>

                {InvoiceType[this.state.invoiceType] === InvoiceType[InvoiceType.Invoice] &&
                    <InvoiceForm
                        project={this.props.project}
                        closeModal={this.props.closeModal}
                        setProject={this.props.setProject}
                        invoiceType={InvoiceType.Invoice}
                        lastPaymentDate={this.state.lastPaymentDate}
                    />
                }
                {InvoiceType[this.state.invoiceType] === InvoiceType[InvoiceType.PartialModules] &&
                    <InvoiceForm
                        project={this.props.project}
                        closeModal={this.props.closeModal}
                        setProject={this.props.setProject}
                        invoiceType={InvoiceType.PartialModules}
                        lastPaymentDate={this.state.lastPaymentDate}
                    />
                }
                {InvoiceType[this.state.invoiceType] === InvoiceType[InvoiceType.Partial] &&
                    <PartialInvoiceForm
                        project={this.props.project}
                        closeModal={this.props.closeModal}
                        setProject={this.props.setProject}
                        lastPaymentDate={this.state.lastPaymentDate}
                    />
                }
                {InvoiceType[this.state.invoiceType] === InvoiceType[InvoiceType.Final] &&
                    <FinalInvoiceForm
                        project={this.props.project}
                        closeModal={this.props.closeModal}
                        setProject={this.props.setProject}
                        lastPaymentDate={this.state.lastPaymentDate}
                        partialInvoiceIds={this.state.partialInvoiceIds}
                    />
                }
            </div>
        );
    }
}

export default InvoiceFactoryForm;