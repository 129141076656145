import axios, { AxiosResponse } from "axios";
import { IDocBasePostDto } from "src/common/dto";


export interface IInvoicePostDto extends IDocBasePostDto {
    percentage?: number,
    textInTable?: string,
    partialInvoiceIds?: number[],
    lastPaymentDate: Date;
    type: string;
}

export const createInvoice = async (invoice: IInvoicePostDto): Promise<AxiosResponse> => {
    const response = await axios.post(`invoice/`, invoice)
    return response;
}

export const getAllInvoices = async (): Promise<AxiosResponse> => {
    const response = await axios.get(`invoice/`)
    return response;
}

export const downloadInvoice = async (id: number): Promise<AxiosResponse> => {
    const response = await axios.get(`invoice/download/${id}`, { responseType: "blob" })
    return response;
}