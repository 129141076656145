import * as React from 'react';
import { Label, Modal, Spinner, SpinnerSize } from '@fluentui/react';

interface LoaderModalProps {
    isOpen: boolean;
    label: string;
}

interface LoaderModalState {

}

class LoaderModal extends React.Component<LoaderModalProps, LoaderModalState> {
    constructor(props: LoaderModalProps) {
        super(props);
        this.state = {};
    }
    render(): React.ReactElement<LoaderModal> {
        return (
            <Modal isOpen={this.props.isOpen}>
                <div className='p-10 w-full h-full flex items-center justify-center flex-col gap-4'>
                    <Label>{this.props.label}</Label>
                    <Spinner size={SpinnerSize.large} />
                </div>
            </Modal>
        );
    }
}

export default LoaderModal;