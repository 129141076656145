import { Checkbox, Dropdown, DropdownMenuItemType, IDropdownOption, Label, PrimaryButton, TextField } from '@fluentui/react';
import * as React from 'react';
import { IModulePostDto, IModulePutDto } from 'src/api/module';
import { buttonColor } from 'src/common/button-style';
import { CUSTOM_TIME_INTERVAL, DAY_TIME_INTERVAL, DEFAULT_DESCRIPTION, HOUR_TIME_INTERVAL, MAX_PRICE, MAX_QTY, UNIT_TIME_INTERVAL, WORKING_HOURS_IN_DAY } from 'src/common/constants';
import { IModuleDto, IProjectDto, ModuleType } from 'src/common/dto';
import { calcPercentage, calcQtyByPercent, showPrice } from 'src/common/functions';
import RichText from 'src/components/common/richText/RichText';

interface OpenModuleFormProps {
    project: IProjectDto;
    module: IModuleDto;
    updateModule?: (id: number, module: IModulePutDto) => void;
    createModule?: (module: IModulePostDto) => void;
}

interface OpenModuleFormState {
    module: IModulePutDto
    errorMessages: {
        timeIntervalRequired: string;
    }
    shownPrice: string;
    shownQty: string;
    percentage: number;
}

class OpenModuleForm extends React.Component<OpenModuleFormProps, OpenModuleFormState> {
    constructor(props: OpenModuleFormProps) {
        super(props);
        this.state = {
            module: JSON.parse(JSON.stringify(this.props.module)),
            shownPrice: this.props.module.price.toLocaleString("De-de"),
            errorMessages: {
                timeIntervalRequired: ''
            },
            shownQty: this.props.module.quantity.toLocaleString("De-de"),
            percentage: calcPercentage(this.props.module.quantity, this.props.module.timeInterval, this.props.project.modules),
        };
    }

    isButtonDisable = (): boolean => {
        if (this.state.module.timeInterval.replaceAll(' ', '') === '')
            return true;

        else if (this.state.module.description.replaceAll(' ', '') === DEFAULT_DESCRIPTION)
            return true;

        else if (this.state.module.description !== this.props.module.description) {
            return false;
        }
        else if (this.state.module.price !== this.props.module.price) {
            return false;
        }
        else if (this.state.module.isOptional !== this.props.module.isOptional) {
            return false;
        }
        else if (this.state.module.quantity !== this.props.module.quantity) {
            return false;
        }
        else if (this.state.module.timeInterval !== this.props.module.timeInterval) {
            return false;
        }
        else if (this.props.module.id === 0)
            return false;
            
        else return true
    }

    changeQty = (event: React.SyntheticEvent<HTMLElement, Event>, newValue?: string | undefined): void => {
        if (newValue !== undefined) {
            newValue = newValue.replace(',', '.').replaceAll(' ', '')

            if (!isNaN(Number(newValue)) && newValue.length <= Number(newValue).toFixed(1).length && Number(newValue) < MAX_QTY && Number(newValue) >= 0) {
                if (newValue.length > 1 && newValue[0] === '0' && newValue[1] !== '.') {
                    newValue = newValue.substring(1, newValue.length);
                }

                const module = this.state.module;
                module.quantity = Number(Number(newValue).toFixed(1));
                const shownQty = newValue.replace('.', ',');
                const percentage = calcPercentage(module.quantity, module.timeInterval, this.props.project.modules)
                this.setState({ module: module, shownQty: shownQty, percentage: percentage });
            }
        }
    }

    onQtyBlur = (): void => {
        const shownQty = this.state.module.quantity.toLocaleString("De-de");
        this.setState({ shownQty: shownQty })
    }

    changeDescription = (description: string): void => {
        const module = this.state.module;
        module.description = description;
        this.setState({ module: module });
    }

    changePrice = (event: React.SyntheticEvent<HTMLElement, Event>, newValue?: string | undefined): void => {
        if (newValue !== undefined) {
            newValue = newValue.replace(',', '.').replaceAll(' ', '')

            if (!isNaN(Number(newValue)) && newValue.length <= Number(newValue).toFixed(2).length && Number(newValue) < MAX_PRICE && Number(newValue) >= 0) {
                if (newValue.length > 1 && newValue[0] === '0' && newValue[1] !== '.') {
                    newValue = newValue.substring(1, newValue.length);
                }

                const module = this.state.module;
                module.price = Number(newValue);
                const shownPrice = newValue.replace('.', ',');
                this.setState({ module: module, shownPrice: shownPrice });
            }
        }
    }

    onPriceFocus = (): void => {
        const shownPrice = this.state.shownPrice.replaceAll('.', '');
        this.setState({ shownPrice: shownPrice })
    }

    onPriceBlur = (): void => {
        const shownPrice = this.state.module.price.toLocaleString("De-de");
        this.setState({ shownPrice: shownPrice })
    }

    onSaveClick = (): void => {
        if (this.props.createModule) {
            const module = this.state.module;

            this.props.createModule({
                description: module.description,
                isOptional: module.isOptional,
                price: module.price,
                projectId: this.props.project.id,
                quantity: module.quantity,
                timeInterval: module.timeInterval,
                sortId: module.sortId,
                type: module.type
            })
        }
        else if (this.props.updateModule)
            this.props.updateModule(this.props.module.id, this.state.module);
    }

    selectedKey = (): string => {
        const timeInterval = this.state.module.timeInterval;

        if (timeInterval === DAY_TIME_INTERVAL || timeInterval === HOUR_TIME_INTERVAL || timeInterval === UNIT_TIME_INTERVAL)
            return timeInterval;
        else
            return CUSTOM_TIME_INTERVAL;
    }

    onTimeChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined): void => {
        const module = this.state.module;
        let shownPrice = this.state.shownPrice;
        let shownQty = this.state.shownQty;

        if (module.timeInterval === DAY_TIME_INTERVAL && option?.text === HOUR_TIME_INTERVAL) {
            module.timeInterval = HOUR_TIME_INTERVAL;
            module.quantity = module.quantity * WORKING_HOURS_IN_DAY;
            shownQty = module.quantity.toLocaleString("De-de");

            if (module.price === this.props.project.defaultPricePerDay)
                module.price = Number(Number((this.props.project.defaultPricePerDay / WORKING_HOURS_IN_DAY).toFixed(2)))
        }
        else if (module.timeInterval === HOUR_TIME_INTERVAL && option?.text === DAY_TIME_INTERVAL) {
            module.timeInterval = DAY_TIME_INTERVAL;
            module.quantity = module.quantity / WORKING_HOURS_IN_DAY;
            shownQty = module.quantity.toLocaleString("De-de");

            if (module.price === Number((this.props.project.defaultPricePerDay / 8)))
                module.price = this.props.project.defaultPricePerDay;
        }
        else if (option?.text === DAY_TIME_INTERVAL || option?.text === HOUR_TIME_INTERVAL || option?.text === UNIT_TIME_INTERVAL)
            module.timeInterval = option.text
        else
            module.timeInterval = ''

        shownPrice = module.price.toLocaleString("De-de");
        this.setState({ module: module, shownPrice: shownPrice, shownQty: shownQty })
    }

    onCustomTimeChange = (event: React.SyntheticEvent<HTMLElement, Event>, newValue?: string | undefined): void => {
        const module = this.state.module;
        module.timeInterval = newValue || ''
        this.setState({ module: module })
    }

    onCustomTimeBlur = (): void => {
        if (this.state.module.timeInterval === '') {
            const errorMessages = this.state.errorMessages;
            errorMessages.timeIntervalRequired = 'Time interval is required'
            this.setState({ errorMessages: errorMessages })
        }
    }

    onCustomTimeFocus = (): void => {
        const errorMessages = this.state.errorMessages;
        errorMessages.timeIntervalRequired = ''
        this.setState({ errorMessages: errorMessages })
    }

    changeOptional = (): void => {
        const module = this.state.module;
        module.isOptional = !this.state.module.isOptional;
        this.setState({ module: module })
    }

    changePercentage = (event: React.SyntheticEvent<HTMLElement, Event>, newValue: string | undefined): void => {
        newValue = newValue?.replace('≈', '');

        if (newValue !== undefined && Number(newValue) < MAX_QTY && Number(newValue) >= 0) {
            const percentage = Number(Number(newValue).toFixed(0));
            const module = this.state.module;
            module.quantity = calcQtyByPercent(percentage, module.timeInterval, this.props.project.modules)
            const shownQty = module.quantity.toLocaleString("De-de");
            this.setState({ percentage: percentage, module: module, shownQty: shownQty })
        }
    }

    render(): React.ReactElement<OpenModuleForm> {
        return (
            <div className='max-h-fit flex flex-col gap-4 mt-4'>
                {this.state.module.type === ModuleType[ModuleType.Module] &&
                    <Checkbox
                        label='Is optional'
                        checked={this.state.module.isOptional}
                        onChange={this.changeOptional}
                    />
                }
                <div className='flex flex-wrap gap-x-10 gap-y-4'>
                    {
                        this.state.module.type !== ModuleType[ModuleType.Module] &&
                        <TextField
                            label='Percentage'
                            value={'≈' + this.state.percentage.toFixed(0)}
                            className='w-28'
                            suffix='%'
                            onChange={this.changePercentage}
                        />
                    }
                    <TextField
                        label='Qty'
                        value={this.state.shownQty}
                        className='w-28'
                        onChange={this.changeQty}
                        onBlur={this.onQtyBlur}
                    />
                    <TextField
                        className='w-28'
                        label='Price'
                        suffix='€'
                        value={this.state.shownPrice}
                        onChange={this.changePrice}
                        onBlur={this.onPriceBlur}
                        onFocus={this.onPriceFocus}
                    />
                    <Dropdown
                        placeholder='Select time interval'
                        className='w-28'
                        label='Time interval'
                        options={[
                            { key: DAY_TIME_INTERVAL, text: DAY_TIME_INTERVAL },
                            { key: UNIT_TIME_INTERVAL, text: UNIT_TIME_INTERVAL },
                            { key: HOUR_TIME_INTERVAL, text: HOUR_TIME_INTERVAL },
                            { key: 'divider_1', text: '-', itemType: DropdownMenuItemType.Divider },
                            { key: CUSTOM_TIME_INTERVAL, text: CUSTOM_TIME_INTERVAL }
                        ]}
                        selectedKey={this.selectedKey()}
                        onChange={this.onTimeChange}
                    />
                    {this.selectedKey() === CUSTOM_TIME_INTERVAL &&
                        <TextField
                            label='Custom time interval'
                            className='w-36'
                            value={this.state.module.timeInterval}
                            onChange={this.onCustomTimeChange}
                            onBlur={this.onCustomTimeBlur}
                            onFocus={this.onCustomTimeFocus}
                            errorMessage={this.state.errorMessages.timeIntervalRequired}
                        />
                    }
                </div>
                <div className='w-635 max-w-full'>
                    <Label>Description</Label>
                    <RichText
                        content={this.state.module.description}
                        setContent={this.changeDescription}
                    />
                </div>
                <div className='flex justify-between mt-2'>
                    <PrimaryButton
                        className={buttonColor(this.isButtonDisable())}
                        text={this.props.module.id === 0 ? 'Create' : 'Save'}
                        onClick={this.onSaveClick}
                        disabled={this.isButtonDisable()}
                    />
                    <div className='text-gray-600 mr-2 mt-2'>{showPrice(this.state.module.price * this.state.module.quantity)}</div>
                </div>
            </div>
        );
    }
}

export default OpenModuleForm;