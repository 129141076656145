import * as React from 'react';

import AddressForm from 'src/forms/AddressForm';
import PartName from '../common/PartName';
import Notiflix from 'notiflix';

import { AxiosError } from 'axios';
import { createAddress, deleteAddress, IAddressPostDto, IAddressPutDto, updateAddress } from 'src/api/address';
import { IAddressDto, ICustomerDto } from 'src/common/dto';

interface AddressProps {
    customer: ICustomerDto;
    setCustomerState: (customer: ICustomerDto) => void
}

interface AddressState {

}

class Address extends React.Component<AddressProps, AddressState> {
    constructor(props: AddressProps) {
        super(props);
    }

    addAddress = (address: IAddressPostDto): void => {
        const response = createAddress(address)

        response.then((response) => {
            if (response.status === 201) {
                Notiflix.Notify.success('Address successfully added');
                this.setNewCustomer(response.data as IAddressDto);
            }
        })
        response.catch((error: AxiosError) => {
            Notiflix.Notify.failure(`${error.response?.data}`)
        })
    }

    updateAddress = (id: number, address: IAddressPutDto): void => {
        const response = updateAddress(address, id)

        response.then((response) => {
            if (response.status === 200) {
                Notiflix.Notify.success('Address successfully updated');
                this.setNewCustomer(response.data as IAddressDto);
            }
        })
        response.catch((error: AxiosError) => {
            Notiflix.Notify.failure(`${error.response?.data}`)
        })
    }

    deleteAddress = (id: number): void => {
        const response = deleteAddress(id)

        response.then((response) => {
            if (response.status === 200) {
                const newCustomer = this.props.customer;
                newCustomer.address = null;
                Notiflix.Notify.success('Address successfully deleted');
                this.props.setCustomerState(newCustomer)
            }
        })
        response.catch((error: AxiosError) => {
            Notiflix.Notify.failure(`${error.response?.data}`)
        })
    }

    setNewCustomer = (address: IAddressDto): void => {
        const newCustomer = this.props.customer
        newCustomer.address = address;

        this.props.setCustomerState(newCustomer)
    }

    render(): React.ReactElement<Address> {
        return (
            <div className='flex flex-col gap-4'>
                <PartName name='Address' />
                {this.props.customer.address ?
                    <AddressForm
                        editMode={{
                            address: this.props.customer.address,
                            deleteAddress: this.deleteAddress,
                            updateAddress: this.updateAddress
                        }}
                        buttonText='Save'
                    />
                    :
                    <AddressForm
                        addMode={{
                            addAddress: this.addAddress,
                            customerId: this.props.customer.id
                        }}
                        buttonText='Add'
                    />
                }
            </div>
        );
    }
}

export default Address;