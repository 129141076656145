import * as React from 'react';
import NavButton from './NavButton';
import UploadModal from '../modals/UploadModal';

interface NavMenuProps {

}

interface NavMenuState {
    showDragAndDropModal: boolean;
}

class NavMenu extends React.Component<NavMenuProps, NavMenuState> {
    constructor(props: NavMenuProps) {
        super(props);
        this.state = {
            showDragAndDropModal: false
        };
    }

    onClick = (): void => {
        this.forceUpdate();
    }

    render(): React.ReactElement<NavMenu> {
        return (
            <div className='w-52 h-body bg-white'>
                <NavButton text='Customers' route='/customers' onClick={this.onClick} />
                <NavButton text='Projects' route='/projects' onClick={this.onClick} />
                <NavButton text='Invoices' route='/invoices' onClick={this.onClick} />
                <NavButton text='Quotations' route='/quotations' onClick={this.onClick} />
                <NavButton text='Upload file' onClick={() => this.setState({ showDragAndDropModal: true })} />

                <UploadModal
                    closeModal={() => this.setState({showDragAndDropModal: false})}
                    isOpen={this.state.showDragAndDropModal}
                />
            </div>
        );
    }
}

export default NavMenu;