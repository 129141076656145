import * as React from 'react';

import { Text } from '@fluentui/react';
import { Link } from 'react-router-dom';

interface NavButtonProps {
    text: string;
    route?: string;
    onClick: () => void;
}

interface NavButtonState {

}

class NavButton extends React.Component<NavButtonProps, NavButtonState> {
    constructor(props: NavButtonProps) {
        super(props);
    }

    isSelected = (): boolean => {
        if (this.props.route)
            return window.location.href.indexOf(this.props.route) !== -1
        else return false
    }

    render(): React.ReactElement<NavButton> {
        return (
            this.props.route ?
                <Link to={this.props.route} onClick={this.props.onClick}>
                    <div className={`${this.isSelected() ? 'nav-button-selected' : 'nav-button-hover'}`} >
                        <div className='pl-8 pt-2 pb-2 cursor-pointer'>
                            <Text variant='medium'>{this.props.text}</Text>
                        </div>
                    </div>
                </Link>
                :
                <div className={`${this.isSelected() ? 'nav-button-selected' : 'nav-button-hover'}`} onClick={this.props.onClick}>
                    <div className='pl-8 pt-2 pb-2 cursor-pointer'>
                        <Text variant='medium'>{this.props.text}</Text>
                    </div>
                </div>
        );
    }
}

export default NavButton;