import * as React from 'react';

import EasyDeleteModal from '../modals/EasyDeleteModal';
import ImportantDeleteModal from '../modals/ImportantDeleteModal';

import { CommandBarButton } from '@fluentui/react';
import { deleteButton } from 'src/common/button-style';
import { deleteIcon, editIcon } from 'src/common/icons';

interface TableSettingElementProps {
    itemName: string;
    onDeleteClick: (id: number) => void
    onEditClick: (id: number) => void;
    elementId: number
    isImportantDeleting: boolean;
}

interface TableSettingElementState {
    isDeleteModalOpen: boolean;
}

class TableSettingElement extends React.Component<TableSettingElementProps, TableSettingElementState> {
    constructor(props: TableSettingElementProps) {
        super(props);
        this.state = ({ isDeleteModalOpen: false })
    }

    changeDeleteModalState = (): void => {
        this.setState({ isDeleteModalOpen: !this.state.isDeleteModalOpen })
    }

    deleteButtonClick = (): void => {
        this.props.onDeleteClick(this.props.elementId);
    }

    editButtonClick = (): void => {
        this.props.onEditClick(this.props.elementId);
    }

    render(): React.ReactElement<TableSettingElement> {
        return (
            <>
                <div className='w-full flex justify-between flex-wrap border-b p-3'>
                    {this.props.children}
                    <div>
                        <CommandBarButton
                            iconProps={editIcon}
                            className='h-11'
                            onClick={this.editButtonClick}
                        />
                        <CommandBarButton
                            iconProps={deleteIcon}
                            className='h-11'
                            theme={deleteButton}
                            onClick={this.changeDeleteModalState}
                        />
                    </div>
                </div>

                {this.props.isImportantDeleting ?
                    <ImportantDeleteModal
                        closeModal={this.changeDeleteModalState}
                        isOpen={this.state.isDeleteModalOpen}
                        onDeleteClick={this.deleteButtonClick}
                        deleteEntity={this.props.itemName}
                    />
                    :
                    <EasyDeleteModal
                        closeModal={this.changeDeleteModalState}
                        isOpen={this.state.isDeleteModalOpen}
                        onDeleteClick={this.deleteButtonClick}
                        deleteEntity={this.props.itemName}
                    />
                }
            </>
        );
    }
}

export default TableSettingElement;