import * as React from 'react';

import { CommandBarButton, Persona, PersonaSize, Text } from '@fluentui/react';
import { Link } from 'react-router-dom';
import { settingIcon } from 'src/common/icons';

interface OverviewProps {
    name: string;
    secondName?: string;
    status?: string;
    button?: JSX.Element;
    url?: string;
}

interface OverviewState {

}

class Overview extends React.Component<OverviewProps, OverviewState> {
    constructor(props: OverviewProps) {
        super(props);
    }
    render(): React.ReactElement<Overview> {
        return (
            <div className='flex bg-white h-24 border-b pt-4 pl-6 justify-between'>
                <div className='flex'>
                    <Persona
                        className='coin-container mt-1'
                        text={this.props.name}
                        secondaryText={this.props.secondName}
                        imageInitials={this.props.name && this.props.name[0].toUpperCase()}
                        size={PersonaSize.size56}
                        styles={{
                            primaryText: {
                                fontWeight: 'bold',
                                fontSize: 25,
                            }
                        }}
                        onRenderSecondaryText={(props) => this.props.url ? <Link className='hover-blue' to={this.props.url}>{this.props.secondName}</Link> : <Text>{this.props.secondName}</Text>}
                    />
                    <Link to={window.location.pathname + '/settings'}>
                        <CommandBarButton
                            iconProps={settingIcon}
                            className='h-8 mt-5'
                        />
                    </Link>
                </div>
                {this.props.status &&
                    <div className={`bg-green-100 text-green-700 text-center p-2 rounded-md h-11 mr-6 mt-2`}>
                        {this.props.status}
                    </div>
                }
                {this.props.button !== undefined &&
                    <div className='mr-6'>
                        {this.props.button}
                    </div>
                }
            </div>
        );
    }
}

export default Overview;