import * as React from 'react';

interface TableElementProps {
    className?: string;
}

interface TableElementState {

}

class TableElement extends React.Component<TableElementProps, TableElementState> {
    constructor(props: TableElementProps) {
        super(props);
    }
    
    render(): React.ReactElement<TableElement> {
        return (
            <div className={'border-b ' + this.props.className}>
                {this.props.children}
            </div>
        );
    }
}

export default TableElement;