import { ProgressIndicator } from '@fluentui/react';
import { AxiosError } from 'axios';
import Notiflix from 'notiflix';
import * as React from 'react';
import { getAllProjects } from 'src/api/project';
import { IProjectDto } from 'src/common/dto';
import { setDocumentTitle } from 'src/common/functions';
import ProjectsList from 'src/components/common/lists/ProjectsList';

interface ProjectsPageProps {
    
}
 
interface ProjectsPageState {
    projects: IProjectDto[],
    loaded: boolean
}
 
class ProjectsPage extends React.Component<ProjectsPageProps, ProjectsPageState> {
    constructor(props: ProjectsPageProps) {
        super(props);
        this.state = {
            projects: [],
            loaded: false,
        };
    }
    componentDidMount(): void {
        setDocumentTitle('Projects')

        const response = getAllProjects()

        response.then(r => {
            if (r.status === 200) {
                this.setState({ projects: r.data, loaded: true })
            }
        })
        response.catch((error: AxiosError) => {
            Notiflix.Notify.failure(error.message);
        })
    }

    render(): React.ReactElement<ProjectsPage> {
            return this.state.loaded ? (
                <div className='p-5'>
                    <ProjectsList
                        projects={this.state.projects}
                        isProjectsPage
                    />
                </div>
            ) : <ProgressIndicator className='mt-m-056' />
    }
}
 
export default ProjectsPage;