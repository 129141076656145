import axios, { AxiosResponse } from "axios";

export interface IPaymentPostDto {
    date: Date;
    price: number;
    invoiceId: number;
}

export interface IPaymentPutDto {
    date: Date;
    price: number;
}

export const addPayment = async (payment: IPaymentPostDto): Promise<AxiosResponse> => {
    const response = await axios.post(`payment`, payment)
    return response;
}

export const updatePayment = async (id: number, payment: IPaymentPutDto): Promise<AxiosResponse> => {
    const response = await axios.put(`payment/${id}`, payment)
    return response;
}

export const deletePayment = async (id: number): Promise<AxiosResponse> => {
    const response = await axios.delete(`payment/${id}`)
    return response;
}