import * as React from 'react';

import Notiflix from 'notiflix';
import RichText from 'src/components/common/richText/RichText';
import LoaderModal from 'src/components/common/modals/LoaderModal';

import { DefaultButton, Label, PrimaryButton } from '@fluentui/react';
import { createInvoice, downloadInvoice, IInvoicePostDto } from 'src/api/invoice';
import { IInvoiceDto, InvoiceType, IProjectDto } from 'src/common/dto';
import { downloadFile, setTextAboveAndBelow } from 'src/common/functions';
import { AxiosError } from 'axios';

interface FinalInvoiceFormProps {
    project: IProjectDto;
    closeModal: () => void;
    setProject: (project: IProjectDto) => void
    lastPaymentDate: Date;
    partialInvoiceIds: number[];
}

interface FinalInvoiceFormState {
    invoice: IInvoicePostDto;
    loadingModalOpen: boolean;
}

class FinalInvoiceForm extends React.Component<FinalInvoiceFormProps, FinalInvoiceFormState> {
    constructor(props: FinalInvoiceFormProps) {
        super(props);
        this.state = {
            invoice: {
                modules: [],
                partialInvoiceIds: this.props.partialInvoiceIds,
                projectId: this.props.project.id,
                textAbove: '',
                textBelow: '',
                type: InvoiceType[InvoiceType.Final],
                lastPaymentDate: this.props.lastPaymentDate
            },
            loadingModalOpen: false
        };
    }

    componentDidMount(): void {
        const invoice = this.state.invoice;
        setTextAboveAndBelow(this.props.project, invoice, undefined)
        this.setState({ invoice })
    }

    componentDidUpdate(prevProps: Readonly<FinalInvoiceFormProps>, prevState: Readonly<FinalInvoiceFormState>): void {
        if (prevProps.lastPaymentDate !== this.props.lastPaymentDate || prevProps.partialInvoiceIds !== this.props.partialInvoiceIds) {
            const invoice = this.state.invoice;
            invoice.lastPaymentDate = this.props.lastPaymentDate;
            invoice.partialInvoiceIds = this.props.partialInvoiceIds;

            this.setState({ invoice })
        }
    }

    changeTextAbove = (textAbove: string): void => {
        const invoice = this.state.invoice;
        invoice.textAbove = textAbove;
        this.setState({ invoice });
    }

    changeTextBelow = (textBelow: string): void => {
        const invoice = this.state.invoice;
        invoice.textBelow = textBelow;
        this.setState({ invoice });
    }

    onSaveClick = (): void => {
        this.setState({ loadingModalOpen: true })
        const project = this.props.project;
        const response = createInvoice(this.state.invoice);

        response.then(r => {
            if (r.status === 201) {
                const invoice = r.data as IInvoiceDto;

                const downloadResponse = downloadInvoice(invoice.id);

                downloadResponse.then(r => {
                    if (r.status === 200) {
                        downloadFile(r);

                        project.invoices.push(invoice);
                        project.defaultInvoiceTextAbove = this.state.invoice.textAbove;
                        project.defaultInvoiceTextBelow = this.state.invoice.textBelow;
                        project.customerDefaultInvoiceTextAbove = this.state.invoice.textAbove;
                        project.customerDefaultInvoiceTextBelow = this.state.invoice.textBelow;
                        Notiflix.Notify.success('Invoice successfully created');
                        this.setState({ loadingModalOpen: false })
                        this.props.setProject(project);
                        this.props.closeModal();
                    }
                })
                downloadResponse.catch((error: AxiosError) => {
                    Notiflix.Notify.failure(`${error.response?.data}`)
                    this.props.closeModal();
                })
            }
        })
        response.catch((error: AxiosError) => {
            Notiflix.Notify.failure(`${error.response?.data}`)
            this.props.closeModal();
        })
    }

    render(): React.ReactElement<FinalInvoiceForm> {
        return (
            <div className='w-1000 flex flex-col gap-4'>
                <div>
                    <Label>Text above</Label>
                    <RichText
                        content={this.state.invoice.textAbove}
                        setContent={this.changeTextAbove}
                    />
                </div>
                <div>
                    <Label>Text below</Label>
                    <RichText
                        content={this.state.invoice.textBelow}
                        setContent={this.changeTextBelow}
                    />
                </div>
                <div className='flex gap-4'>
                    <PrimaryButton
                        text='Create'
                        className={this.state.invoice.partialInvoiceIds?.length === 0 ? 'disabled-button' : 'primary-button'}
                        disabled={this.state.invoice.partialInvoiceIds?.length === 0}
                        onClick={this.onSaveClick}
                    />
                    <DefaultButton
                        text='Cancel'
                        onClick={this.props.closeModal}
                    />
                </div>

                <LoaderModal isOpen={this.state.loadingModalOpen} label='Document is creating, please wait' />
            </div>
        );
    }
}

export default FinalInvoiceForm;