import axios, { AxiosResponse } from "axios";
import { Language } from "src/common/dto";

export interface ICustomerPostRequest {
    name: string;
    contractor?: string | null;
    defaultPricePerDay: number;
    tax: number;
    to?: string | null;
    cc?: string | null;
    text?: string | null;
    subject?: string | null;
}

export const addCustomer = async (customer: ICustomerPostRequest, language: Language): Promise<AxiosResponse> => {
    const response = await axios.post(`customer?language=${language}`, customer)
    return response;
}

export const getCustomer = async (id: number): Promise<AxiosResponse> => {
    const response = await axios.get(`customer/${id}`)
    return response;
}

export const getAllCustomers = async (): Promise<AxiosResponse> => {
    const response = await axios.get(`customer/`)
    return response;
}

export const updateCustomer = async (id: number, customer: ICustomerPostRequest, language: Language): Promise<AxiosResponse> => {
    const response = await axios.put(`customer/${id}?language=${language}`, customer)
    return response;
}

export const deleteCustomer = async (id: number): Promise<AxiosResponse> => {
    const response = await axios.delete(`customer/${id}`)
    return response;
}

export const createEmail = async (id: number): Promise<AxiosResponse> => {
    const response = await axios.get(`customer/generate-eml/${id}`, { responseType: "blob" })
    return response;
}