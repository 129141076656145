import * as React from 'react';
import Dropzone from 'react-dropzone';
import { uploadFile } from 'src/api/sharePoint';
import Notiflix from 'notiflix';
import { AxiosError } from 'axios';
import { Icon, Text } from '@fluentui/react';
import LoaderModal from './LoaderModal';
import RockettaModal from './RockettaModal';

interface UploadModalProps {
    closeModal: () => void;
    isOpen: boolean;
    name?: string;
    className?: string
}

interface UploadModalState {
    loadingMenuOpen: boolean;
    borderBlue: boolean;
}

class UploadModal extends React.Component<UploadModalProps, UploadModalState> {
    constructor(props: UploadModalProps) {
        super(props);
        this.state = { loadingMenuOpen: false, borderBlue: false };
    }

    onUpload = (files: File[]): void => {
        this.setState({ loadingMenuOpen: true })
        files.map(file => {
            const formData = new FormData();
            formData.append('file', file);
            const response = uploadFile(formData);
            response.then(r => {
                if (r.status === 200) {
                    Notiflix.Notify.success(`${file.name} successfully uploaded`);
                }
            })
            response.catch((error: AxiosError) => {
                Notiflix.Notify.failure(`${file.name} uploading failed`);
            })
            response.finally(() => {
                if (files.indexOf(file) === files.length - 1) {
                    this.setState({ loadingMenuOpen: false, borderBlue: false })
                    this.props.closeModal();
                }
            })
        })
    }

    render(): React.ReactElement<UploadModal> {
        return (
            <div>
                <RockettaModal
                    closeModal={this.props.closeModal}
                    isOpen={this.props.isOpen}
                    className={this.props.className}
                    name={this.props.name}
                >

                    <div className={`h-52 border border-dashed ${this.state.borderBlue ? 'border-blue-400' : 'border-gray-400'} w-full`}>
                        <Dropzone
                            multiple={true}
                            onDrop={acceptedFiles => this.onUpload(acceptedFiles)}
                            onDragEnter={() => this.setState({ borderBlue: true })}
                            onDragLeave={() => this.setState({ borderBlue: false })}
                            noDragEventsBubbling={true}
                            onDropRejected={() => this.setState({loadingMenuOpen: false})}
                            accept={{
                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                                'application/pdf': ['.pdf']
                            }}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps({ className: 'dropzone' })} className='h-full items-center flex flex-col gap-2 cursor-pointer px-10 py-6'>
                                    <input {...getInputProps()} />
                                    <Icon
                                        iconName='CloudUpload'
                                        styles={{ root: { fontSize: 50, color: "#0078d4" } }}
                                    />
                                    <div>
                                        <Text variant='large'>Click here to upload a file to sharepoint</Text>
                                    </div>
                                    <div>
                                        <Text variant='medium' styles={{ root: { color: 'gray' } }}>Only .docx and .pdf files can be uploaded</Text>
                                    </div>
                                </div>
                            )}
                        </Dropzone>
                    </div>
                </RockettaModal>

                <LoaderModal isOpen={this.state.loadingMenuOpen} label='Documents are uploading, please wait' />
            </div >
        );
    }
}

export default UploadModal;