import axios, { AxiosResponse } from "axios"

export interface IContactPersonPostRequest {
    firstName: string,
    lastName: string | null,
    email: string | null,
    phone: string | null
    customerId: number
}

export interface IContactPersonPutRequest {
    firstName: string,
    lastName: string | null,
    email: string | null,
    phone: string | null
}

export const addPerson = async (person: IContactPersonPostRequest): Promise<AxiosResponse> => {
    const response = await axios.post(`contactPerson`, person)
    return response;
}

export const deletePerson = async (id: number): Promise<AxiosResponse> => {
    const response = await axios.delete(`contactPerson/${id}`)
    return response;
}

export const updatePerson = async (id: number, person: IContactPersonPutRequest): Promise<AxiosResponse> => {
    const response = await axios.put(`contactPerson/${id}`, person)
    return response;
}