import * as React from 'react';

import Notiflix from 'notiflix';
import RichText from 'src/components/common/richText/RichText';
import LoaderModal from 'src/components/common/modals/LoaderModal';
import ModuleList, { IModuleListElement } from 'src/components/common/lists/ModuleList';

import { Label, PrimaryButton, DefaultButton } from '@fluentui/react';
import { createQuotation, downloadQuotation, IQuotationPostDto } from 'src/api/quotation';
import { DocType, IModuleDto, IProjectDto, IQuotationDto } from 'src/common/dto';
import { downloadFile, setTextAboveAndBelow } from 'src/common/functions';
import { AxiosError } from 'axios';

interface QuotationFormProps {
    project: IProjectDto;
    closeModal: () => void;
    setProject: (project: IProjectDto) => void
}

interface QuotationFormState {
    modules: IModuleDto[];
    quotation: IQuotationPostDto | null
    chosenModules: IModuleDto[]
    loadingModalOpen: boolean;
}

class QuotationForm extends React.Component<QuotationFormProps, QuotationFormState> {
    constructor(props: QuotationFormProps) {
        super(props);
        this.state = {
            modules: JSON.parse(JSON.stringify(this.props.project.modules)),
            chosenModules: [],
            quotation: null,
            loadingModalOpen: false,
        };
    }

    componentDidMount = (): void => {
        const quotation: IQuotationPostDto = {
            modules: [],
            projectId: this.props.project.id,
            textAbove: '',
            textBelow: ''
        };

        setTextAboveAndBelow(this.props.project, undefined, quotation)

        this.setState({ quotation })
    }

    changeTextAbove = (text: string): void => {
        const quotation = this.state.quotation;
        if (quotation) {
            quotation.textAbove = text;
            this.setState({ quotation: quotation })
        }
    }

    changeTextBelow = (text: string): void => {
        const quotation = this.state.quotation;
        if (quotation) {
            quotation.textBelow = text;
            this.setState({ quotation: quotation })
        }
    }

    onSelectionClick = (modules: IModuleListElement[]): void => {
        const chosenModules: IModuleDto[] = [];

        modules.forEach(m => {
            chosenModules.push(this.state.modules.filter(module => module.id === +m.key)[0]);
        })

        this.setState({ chosenModules })
    }

    onCreateClick = async (): Promise<void> => {
        this.setState({ loadingModalOpen: true })
        const quotation = this.state.quotation;

        if (quotation) {
            this.state.chosenModules.map(m => quotation.modules.push(m));

            const response = createQuotation(quotation);

            response.then(r => {
                if (r.status === 201) {
                    const createdQuotation = r.data as IQuotationDto;

                    const downloadResponse = downloadQuotation(createdQuotation.id);

                    downloadResponse.then(r => {
                        if (r.status === 200) {
                            downloadFile(r);

                            const project = this.props.project;
                            project.quotations.push(createdQuotation);
                            project.defaultQuotationTextAbove = quotation.textAbove;
                            project.defaultQuotationTextBelow = quotation.textBelow;
                            project.customerDefaultQuotationTextAbove = quotation.textAbove;
                            project.customerDefaultQuotationTextBelow = quotation.textBelow;
                            Notiflix.Notify.success('Quotation successfully created');
                            this.setState({ loadingModalOpen: false })
                            this.props.setProject(project);
                            this.props.closeModal();
                        }
                    })
                    downloadResponse.catch((error: AxiosError) => {
                        Notiflix.Notify.failure(`${error.response?.data}`)
                        this.props.closeModal();
                    })
                }
            })
            response.catch((error: AxiosError) => {
                Notiflix.Notify.failure(`${error.response?.data}`)
                this.props.closeModal();
            })
        }
    }

    render(): React.ReactElement<QuotationForm> {
        return this.state.quotation ? (
            <div className='w-1000 flex flex-col gap-4'>
                {this.state.modules.length > 0 ?
                    <ModuleList
                        tax={this.props.project.tax}
                        modules={this.state.modules}
                        onSelectionClick={this.onSelectionClick}
                        documentType={DocType.Quotation}
                    />
                    : null
                }
                <div className='max-w-full'>
                    <Label>Text above</Label>
                    <RichText
                        content={this.state.quotation.textAbove}
                        setContent={this.changeTextAbove}
                    />
                </div>
                <div className='max-w-full'>
                    <Label>Text below</Label>
                    <RichText
                        content={this.state.quotation.textBelow}
                        setContent={this.changeTextBelow}
                    />
                </div>

                <div className='flex gap-5'>
                    <PrimaryButton
                        text='Create'
                        className={this.state.chosenModules.length === 0 ? 'disabled-button' : 'primary-button'}
                        disabled={this.state.chosenModules.length === 0}
                        onClick={this.onCreateClick}
                    />
                    <DefaultButton
                        text='Cancel'
                        onClick={this.props.closeModal}
                    />
                </div>

                <LoaderModal isOpen={this.state.loadingModalOpen} label='Quotation is creating, please wait' />
            </div>
        ) : <div className='w-1000 h-screen'></div>
    }
}

export default QuotationForm;