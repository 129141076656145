import { IIconProps, initializeIcons } from "@fluentui/react";

initializeIcons()
export const addIcon: IIconProps = { iconName: 'Add' };
export const deleteIcon: IIconProps = { iconName: 'Delete' };
export const editIcon: IIconProps = { iconName: 'Edit' };
export const cancelIcon: IIconProps = { iconName: 'Cancel' };
export const checkMarkIcon: IIconProps = { iconName: 'CheckMark' };
export const settingIcon: IIconProps = { iconName: 'Settings' };
export const chevronDownIcon: IIconProps = {iconName: 'ChevronDown'};
export const chevronUpIcon: IIconProps = {iconName: 'ChevronUp'};
export const calendarIcon: IIconProps = {iconName: 'Calendar'};
export const downloadIcon: IIconProps = {iconName: 'Download'};