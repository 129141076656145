import * as React from 'react';

import ContactData from 'src/components/customerPassportPage/ContactData';
import Projects from 'src/components/customerPassportPage/Projects';
import Overview from 'src/components/common/Overview';
import Notiflix from 'notiflix';

import { createEmail, getCustomer } from 'src/api/customer';
import { ICustomerDto } from 'src/common/dto';
import { Navigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { PrimaryButton, ProgressIndicator } from '@fluentui/react';
import { buttonColor } from 'src/common/button-style';
import { downloadFile, setDocumentTitle } from 'src/common/functions';
import LoaderModal from 'src/components/common/modals/LoaderModal';

interface CustomerPassportPageProps {

}

interface CustomerPassportPageState {
    customer: ICustomerDto;
    isNotFound: boolean;
    loaded: boolean;
    saving: boolean;
}

class CustomerPassportPage extends React.Component<CustomerPassportPageProps, CustomerPassportPageState> {
    constructor(props: CustomerPassportPageProps) {
        super(props);
        this.state = {
            customer: {} as ICustomerDto,
            isNotFound: false,
            loaded: false,
            saving: false
        };
    }

    getCustomer = (id: number): void => {
        const response = getCustomer(id);

        response.then(r => {
            if (r.status === 200) {
                const customer: ICustomerDto = r.data;
                setDocumentTitle(customer.name);
                
                this.setState({ customer, loaded: true })
            }
        })
        response.catch((error: AxiosError) => {
            if (error.response?.status === 404)
                this.setState({ isNotFound: true })
            else
                Notiflix.Notify.failure(error.message);
        })
    }

    getCustomerId = (pathName: string): string => {
        const directories = pathName.split("/");
        const lastDirectory = directories[(directories.length - 1)];
        return lastDirectory;
    }

    componentDidMount = (): void => {
        const path = window.location.pathname;
        const id = this.getCustomerId(path);
        this.getCustomer(parseInt(id));
    }

    setCustomerState = (customer: ICustomerDto): void => {
        this.setState({ customer: JSON.parse(JSON.stringify(customer)) })
    }

    onCreateEmailClick = async (): Promise<void> => {
        this.setState({ saving: true })

        const response = createEmail(this.state.customer.id);
        response.then(r => {
            if (r.status === 200) {
                downloadFile(r)
            }
        })
        response.catch((error: AxiosError) => {
            Notiflix.Notify.failure(error.message);
        })
        response.finally(() => this.setState({saving: false}))
    }

    render(): React.ReactElement<CustomerPassportPage> {
        return this.state.loaded ? (
            <div>
                <Overview
                    name={this.state.customer.name}
                    secondName={this.state.customer.contractor || undefined}
                    button={
                        <PrimaryButton
                            text='Create email'
                            className={buttonColor(false) + ' h-10  mt-3'}
                            onClick={this.onCreateEmailClick}
                        />
                    }
                />

                <div className='p-5 flex flex-col'>
                    <ContactData customer={this.state.customer} />
                    <Projects customer={this.state.customer} setCustomerState={this.setCustomerState} />
                </div>

                <LoaderModal isOpen={this.state.saving} label='Email message is creating, please wait' />
            </div>
        ) : this.state.isNotFound ? <Navigate to='/notFound' replace={true} /> : <ProgressIndicator className='mt-m-056' />
    }
}

export default CustomerPassportPage;