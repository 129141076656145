import * as React from 'react';

interface LineProps {
    className?: string
}

interface LineState {

}

class Line extends React.Component<LineProps, LineState> {
    render(): React.ReactElement<Line> {
        return (<div className={this.props.className + ' border-gray-400 border-b h-0'} />);
    }
}

export default Line;