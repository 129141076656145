import * as React from 'react';

import { Dropdown, IDropdownOption, PrimaryButton, TextField } from '@fluentui/react';
import { IProjectPostRequest, IProjectPutRequest } from 'src/api/project';
import { buttonColor } from 'src/common/button-style';
import { ICustomerDto, IProjectDto, Language } from 'src/common/dto';
import { MAX_PERCENTAGE, TAX } from 'src/common/constants';

interface ProjectFormProps {
    editMode?: {
        project: IProjectDto;
        editProject: (id: number, project: IProjectPutRequest, language: Language) => void
    }
    addMode?: {
        customer: ICustomerDto;
        addProject: (project: IProjectPostRequest, language: Language) => void
    }
    buttonText: string,
    className?: string
}

interface ProjectFormState {
    project: {
        name: string;
        defaultPricePerDay: number;
        language: Language,
        tax: number,
        status: string,
        customerOrderNo: string;
    }
    errorMessages: {
        nameRequired: string
    },
    shownPrice: string
}

class ProjectForm extends React.Component<ProjectFormProps, ProjectFormState> {
    constructor(props: ProjectFormProps) {
        super(props);
        this.state = {
            project: {
                name: this.props.editMode?.project.name || '',
                defaultPricePerDay: this.props.editMode?.project.defaultPricePerDay ||
                    this.props.addMode?.customer.defaultPricePerDay || 0,
                tax: this.props.addMode?.customer.tax || this.props.editMode?.project.tax || TAX,
                language: this.props.addMode?.customer.language
                    || this.props.editMode?.project.language
                    || Language[Language.English] as unknown as Language,
                status: this.props.editMode ? this.props.editMode.project.status : 'Proposal requested',
                customerOrderNo: this.props.editMode?.project.customerOrderNo || ''
            },
            errorMessages: {
                nameRequired: ''
            },
            shownPrice: (this.props.editMode?.project.defaultPricePerDay ||
                this.props.addMode?.customer.defaultPricePerDay || 0).toLocaleString("De-de")
        }
    }

    sendRequest = (): void => {
        const project: IProjectPutRequest = {
            name: this.state.project.name,
            defaultPricePerDay: this.state.project.defaultPricePerDay,
            tax: this.state.project.tax,
            status: this.state.project.status,
            customerOrderNo: this.state.project.customerOrderNo
        }

        if (this.props.addMode)
            this.props.addMode.addProject({
                name: project.name, 
                defaultPricePerDay: project.defaultPricePerDay,
                status: project.status,
                customerOrderNo: project.customerOrderNo,
                tax: project.tax,
                customerId: this.props.addMode.customer.id
            }, this.state.project.language)

        else if (this.props.editMode)
            this.props.editMode.editProject(this.props.editMode.project.id, project, this.state.project.language);
    }

    isButtonDisable = (): boolean => {
        const project = {
            name: this.state.project.name,
            language: this.state.project.language,
            defaultPricePerDay: this.state.project.defaultPricePerDay,
            tax: this.state.project.tax,
            status: this.state.project.status,
            customerOrderNo: this.state.project.customerOrderNo
        }

        if (project.name === '')
            return true;
        else if (project.name !== this.props.editMode?.project.name)
            return false
        else if (project.language !== this.props.editMode?.project.language)
            return false
        else if (project.defaultPricePerDay !== this.props.editMode?.project.defaultPricePerDay)
            return false
        else if (project.tax !== this.props.editMode?.project.tax)
            return false
        else if (project.customerOrderNo !== this.props.editMode?.project.customerOrderNo)
            return false
        else if (project.status !== this.props.editMode?.project.status)
            return false

        else return true
    }

    setName = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string | undefined): void => {
        const project = this.state.project;
        project.name = newValue || '';
        const errorMessages = this.state.errorMessages;
        if (project.name === '')
            errorMessages.nameRequired = 'Name is required'
        else
            errorMessages.nameRequired = ''

        this.setState({ project: project, errorMessages: errorMessages })
    }

    changeLanguage = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
        const project = this.state.project;
        project.language = option?.text as unknown as Language
        this.setState({ project: project })
    }

    changeStatus = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
        if (option) {
            const project = this.state.project;
            project.status = option?.text;
            this.setState({ project })
        }
    }

    changeTax = (event: React.SyntheticEvent<HTMLElement, Event>, newValue: string | undefined): void => {
        const project = this.state.project;
        if (project) {
            project.tax = Number(newValue)
            if (!isNaN(project.tax) && project.tax <= MAX_PERCENTAGE)
                this.setState({ project })
        }
    }

    changePrice = (event: React.SyntheticEvent<HTMLElement, Event>, newValue?: string | undefined): void => {
        if (newValue !== undefined) {
            newValue = newValue?.replace(',', '.')

            if (!isNaN(Number(newValue)) && newValue.length <= Number(newValue).toFixed(2).length) {
                if (newValue.length > 1 && newValue[0] === '0' && newValue[1] !== '.') {
                    newValue = newValue.substring(1, newValue.length);
                }

                const project = this.state.project;
                project.defaultPricePerDay = Number(newValue);
                const shownPrice = newValue.replace('.', ',');
                this.setState({ project: project, shownPrice: shownPrice });
            }
        }
    }

    onPriceFocus = (): void => {
        const shownPrice = this.state.shownPrice.replaceAll('.', '');
        this.setState({ shownPrice: shownPrice })
    }

    onPriceBlur = (): void => {
        const shownPrice = this.state.project.defaultPricePerDay.toLocaleString("De-de");
        this.setState({ shownPrice: shownPrice })
    }

    setCustomerOrderNo = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string | undefined): void => {
        const project = this.state.project;
        project.customerOrderNo = newValue || ''
        this.setState({ project})
    }

    render(): React.ReactElement<ProjectForm> {
        return (
            <div className={this.props.className}>
                <div className='flex flex-col gap-4 w-80'>
                    <TextField
                        label='Name' required
                        onChange={this.setName}
                        value={this.state.project.name}
                        errorMessage={this.state.errorMessages.nameRequired}
                    />
                    <TextField
                        label='Tax'
                        suffix='%'
                        value={this.state.project.tax.toString()}
                        onChange={this.changeTax}
                    />
                    <TextField
                        label='Default price per day'
                        suffix='€'
                        value={this.state.shownPrice}
                        onChange={this.changePrice}
                        onBlur={this.onPriceBlur}
                        onFocus={this.onPriceFocus}
                    />
                    <Dropdown
                        selectedKey={Language[this.state.project.language]}
                        label='Language'
                        options={[
                            { key: Language.English, text: Language[0] },
                            { key: Language.German, text: Language[1] }
                        ]}
                        onChange={this.changeLanguage}
                    />
                    <TextField
                        label='Customer Order №'
                        value={this.state.project.customerOrderNo}
                        onChange={this.setCustomerOrderNo}
                    />
                    <Dropdown
                        selectedKey={this.state.project.status}
                        label='Status'
                        options={[
                            { key: "Idea", text: "Idea" },
                            { key: "Proposal requested", text: "Proposal requested" },
                            { key: "Proposal sent", text: "Proposal sent" },
                            { key: "Ordered", text: "Ordered" },
                            { key: "Running", text: "Running" },
                            { key: "Completed", text: "Completed" },
                            { key: "Closed", text: "Closed" },
                        ]}
                        onChange={this.changeStatus}
                    />

                </div>

                <PrimaryButton onClick={this.sendRequest}
                    disabled={this.isButtonDisable()}
                    className={buttonColor(this.isButtonDisable()) + ' mt-6'}
                    text={this.props.buttonText}
                />
            </div>
        );
    }
}

export default ProjectForm;