import * as React from 'react';

import PartName from 'src/components/common/PartName';
import Line from 'src/components/common/Line';
import ProjectForm from 'src/forms/ProjectForm';
import DeleteModal from 'src/components/common/modals/ImportantDeleteModal';
import Notiflix from 'notiflix';

import { PrimaryButton, ProgressIndicator, Text } from '@fluentui/react';
import { Navigate } from 'react-router-dom';
import { deleteProject, getProject, IProjectPutRequest, updateProject } from 'src/api/project';
import { IProjectDto, Language } from 'src/common/dto';
import { AxiosError } from 'axios';
import { setDocumentTitle } from 'src/common/functions';

interface ProjectSettingsPageProps {

}

interface ProjectSettingsPageState {
    project: IProjectDto;
    isNotFound: boolean;
    isRedirectToCustomer: boolean;
    isDeleteModalOpen: boolean;
    loaded: boolean;
}

class ProjectSettingsPage extends React.Component<ProjectSettingsPageProps, ProjectSettingsPageState> {
    constructor(props: ProjectSettingsPageProps) {
        super(props);
        this.state = {
            project: {} as IProjectDto,
            isNotFound: false,
            isRedirectToCustomer: false,
            isDeleteModalOpen: false,
            loaded: false
        }
    }

    getProject = (id: number): void => {
        const response = getProject(id);

        response.then((response) => {
            if (response.status === 200){
                const project: IProjectDto = response.data;
                setDocumentTitle(`${project.name} Settings`);
                this.setState({ project, loaded: true })
            }
        })
        response.catch((error: AxiosError) => {
            if (error.response?.status === 404)
                this.setState({ isNotFound: true })
            else
                Notiflix.Notify.failure(error.message);
        })
    }

    getProjectId = (pathName: string): string => {
        const directories = pathName.split("/");
        const lastDirectory = directories[(directories.length - 2)];
        return lastDirectory;
    }

    componentDidMount = (): void => {
        const path = window.location.pathname;
        const id = this.getProjectId(path);
        this.getProject(parseInt(id));
    }

    updateProject = (id: number, project: IProjectPutRequest, language: Language): void => {
        const response = updateProject(id, project, language);

        response.then((response) => {
            if (response.status === 200) {
                Notiflix.Notify.success('Project successfully updated');
                this.setState({ project: response.data })
            }
        })
        response.catch((error: AxiosError) => {
            Notiflix.Notify.failure(`${error.response?.data}`)
        })
    }

    deleteProject = (): void => {
        const response = deleteProject(this.state.project.id);

        response.then(r => {
            if (r.status === 200) {
                Notiflix.Notify.success('Project successfully deleted');
                this.setState({ isRedirectToCustomer: true })
            }
        })
        response.catch((error: AxiosError) => {
            Notiflix.Notify.failure(`${error.response?.data}`)
        })
    }

    changeDeleteModalState = (): void => {
        this.setState({ isDeleteModalOpen: !this.state.isDeleteModalOpen })
    }

    render(): React.ReactElement<ProjectSettingsPage> {
        return this.state.loaded ? (
            <div className='p-5 flex flex-col gap-6'>
                {this.state.isRedirectToCustomer && <Navigate to={'/customers/' + this.state.project.customerId} replace={true} />}

                <PartName name='Project details' />
                <ProjectForm
                    buttonText='Save'
                    editMode={{
                        project: this.state.project,
                        editProject: this.updateProject
                    }}
                />
                <Line />
                <div>
                    <PartName name='Delete project' />
                    <div className='mt-2'>
                        <Text className='text-base text-gray-600'>
                            If you delete this project all information which connected with him will be deleted permanently
                        </Text>
                    </div>
                    <PrimaryButton
                        className='delete-button mt-5'
                        text='Delete project'
                        onClick={this.changeDeleteModalState}
                    />
                </div>
                <DeleteModal
                    closeModal={this.changeDeleteModalState}
                    isOpen={this.state.isDeleteModalOpen}
                    onDeleteClick={this.deleteProject}
                    deleteEntity={this.state.project.name}
                />
            </div>
        ) : this.state.isNotFound ? <Navigate to='/notFound' replace={true} /> : <ProgressIndicator className='mt-m-056' />;
    }
}

export default ProjectSettingsPage;