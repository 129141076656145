import * as React from 'react';

import EasyDeleteModal from 'src/components/common/modals/EasyDeleteModal';
import OpenModuleForm from './OpenModuleForm';

import { CommandBarButton, IconButton, Text } from '@fluentui/react';
import { IModulePutDto } from 'src/api/module';
import { deleteButton } from 'src/common/button-style';
import { IModuleDto, IProjectDto, ModuleType } from 'src/common/dto';
import { showModuleName, showPrice } from 'src/common/functions';
import { chevronDownIcon, chevronUpIcon, deleteIcon } from 'src/common/icons';


interface ModuleFormProps {
    defaultName: string
    project: IProjectDto;
    module: IModuleDto;
    deleteModule: (id: number) => void;
    updateModule: (id: number, module: IModulePutDto) => void;
    updateModuleSort: (module: IModuleDto[]) => void
}

interface ModuleFormState {
    isModuleOpen: boolean;
    isDeleteModalOpen: boolean;
}

class ModuleForm extends React.Component<ModuleFormProps, ModuleFormState> {
    constructor(props: ModuleFormProps) {
        super(props);
        this.state = {
            isModuleOpen: false,
            isDeleteModalOpen: false,
        }
    }

    changeDeleteModal = (): void => {
        this.setState({ isDeleteModalOpen: !this.state.isDeleteModalOpen })
    }

    changeModuleState = (): void => {
        this.setState({ isModuleOpen: !this.state.isModuleOpen })
    }

    onDeleteClick = (): void => {
        this.props.deleteModule(this.props.module.id)
        this.changeModuleState()
    }

    filterWithSameTypes = (): IModuleDto[] => {
        if (this.props.module.type === ModuleType[ModuleType.Module])
            return this.props.project.modules.filter(m => m.type === ModuleType[ModuleType.Module])
        else
            return this.props.project.modules.filter(m => m.type !== ModuleType[ModuleType.Module])
    }

    onChevronUpClick = async (): Promise<void> => {
        const filterWithSameTypes = this.filterWithSameTypes();
        const indexOfModule = filterWithSameTypes.indexOf(this.props.module);
        const prevIndex = indexOfModule !== 0 ? indexOfModule - 1 : filterWithSameTypes.length - 1;

        filterWithSameTypes[indexOfModule].sortId = prevIndex;
        filterWithSameTypes[prevIndex].sortId = indexOfModule;

        this.props.updateModuleSort([filterWithSameTypes[indexOfModule], filterWithSameTypes[prevIndex]])
    }

    onChevronDownClick = async (): Promise<void> => {
        const filterWithSameTypes = this.filterWithSameTypes();
        const indexOfModule = filterWithSameTypes.indexOf(this.props.module);
        const prevIndex = indexOfModule !== filterWithSameTypes.length - 1 ? indexOfModule + 1 : 0;

        filterWithSameTypes[indexOfModule].sortId = prevIndex;
        filterWithSameTypes[prevIndex].sortId = indexOfModule;

        this.props.updateModuleSort([filterWithSameTypes[indexOfModule], filterWithSameTypes[prevIndex]])
    }

    render(): React.ReactElement<ModuleForm> {
        return (
            <div className='p-2'>
                <div className='flex justify-between h-9'>
                    <div className='flex'>
                        <CommandBarButton
                            iconProps={this.state.isModuleOpen ? chevronUpIcon : chevronDownIcon}
                            onClick={this.changeModuleState}
                            label='chevron'
                            ariaLabel='chevron'
                        />
                        <Text variant='mediumPlus' className='m-1 w-635' nowrap>
                            {showModuleName(this.props.module.description)}
                        </Text>
                    </div>
                    {this.state.isModuleOpen ?
                        <div>
                            <CommandBarButton
                                iconProps={deleteIcon}
                                className='h-11'
                                theme={deleteButton}
                                onClick={this.changeDeleteModal}
                            />
                        </div>
                        :
                        <div className='flex gap-1'>
                            <div className='text-gray-600 m-1 h-9 self-center pt-1'>
                                {`${this.props.module.quantity} ${this.props.module.timeInterval}`}
                            </div>
                            <div className='text-gray-600 m-1 pt-1 w-24 h-9 border-l-2 border-solid border-gray-200 pl-2 self-center'>
                                {`${showPrice(this.props.module.price * this.props.module.quantity)}`}
                            </div>
                            <div className='border-l-2 border-solid border-gray-200 pl-2 self-center'>
                                <CommandBarButton
                                    iconProps={deleteIcon}
                                    className='h-9'
                                    theme={deleteButton}
                                    onClick={this.changeDeleteModal}
                                />
                            </div>
                            <div className='flex flex-col gap-1 border-l-2 border-solid border-gray-200 pl-2 h-9'>
                                <IconButton
                                    iconProps={{ iconName: 'ChevronUp' }}
                                    onClick={this.onChevronUpClick}
                                />
                                <IconButton
                                    iconProps={{ iconName: 'ChevronDown' }}
                                    onClick={this.onChevronDownClick}
                                />
                            </div>
                        </div>
                    }
                </div>

                {this.state.isModuleOpen &&
                    <div className='ml-11'>
                        <OpenModuleForm
                            module={this.props.module}
                            project={this.props.project}
                            updateModule={this.props.updateModule}
                        />
                    </div>
                }
                <EasyDeleteModal
                    closeModal={this.changeDeleteModal}
                    isOpen={this.state.isDeleteModalOpen}
                    deleteEntity='position'
                    onDeleteClick={() => this.props.deleteModule(this.props.module.id)}
                />
            </div >
        );
    }
}

export default ModuleForm;