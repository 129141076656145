import * as React from 'react';
import { IProjectDto } from 'src/common/dto';
import PartName from '../PartName';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { ColumnFilterItem } from 'antd/es/table/interface';
import { Text } from '@fluentui/react';
import { showDate } from 'src/common/functions';
import Project from '../entities/Project';

interface ProjectsListProps {
    projects: IProjectDto[]
    isProjectsPage?: true;
}

interface ProjectsListState {
    columns: ColumnsType<IProjectDto>;
}

class ProjectsList extends React.Component<ProjectsListProps, ProjectsListState> {
    constructor(props: ProjectsListProps) {
        super(props);
        this.state = {
            columns: [

            ],
        };
    }

    componentDidMount(): void {
        const columns: ColumnsType<IProjectDto> = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                render: (text, record) => this.props.isProjectsPage ? <Link to={`/projects/${record.id}`}>{text}</Link> : <Project project={record} />,
                sorter: (a, b) => a.name.localeCompare(b.name),
                filters: this.props.projects
                    .map(r => { return { text: r.name, value: r.name } })
                    .reduce((accum: ColumnFilterItem[], val: ColumnFilterItem) => accum.some(v => v.text === val.text) ? accum : [...accum, val], []),
                filterSearch: true,
                onFilter: (value, record) => {
                    return record.name === value
                },
                width: '20%',
            },
            {
                title: 'Customer Order №',
                dataIndex: 'customerOrderNo',
                key: 'customerOrderNo',
                render: (text) => <Text className='text-gray'>{text}</Text>,
                sorter: (a, b) => a.customerOrderNo.localeCompare(b.customerOrderNo),
                width: '10%',
            },
            {
                title: 'Created Date',
                dataIndex: 'createdDate',
                key: 'createdDate',
                render: (text) => <Text className='text-gray'>{showDate(new Date(text))}</Text>,
                sorter: (a, b) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime(),
                width: '10%',
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (text, record) => text ?
                    <div
                        key={record.id}
                        className={`bg-green-100 text-green-700 text-center rounded-md h-full w-fit p-2 text-xs`}
                    >
                        {text}
                    </div> : '',
                filters: this.props.projects
                    .filter(r => r.status)
                    .map(r => { return { text: r.status, value: r.status } })
                    .reduce((accum: ColumnFilterItem[], val: ColumnFilterItem) => accum.some(v => v.text === val.text) ? accum : [...accum, val], []),
                filterSearch: true,
                onFilter: (value, record) => {
                    return record.status === value
                },
                sorter: (a, b) => a.status.localeCompare(b.status),
                width: '10%'
            },
        ]

        if (this.props.isProjectsPage)
            columns.splice(1, 0,
                {
                    title: 'Customer',
                    dataIndex: 'customerName',
                    key: 'customerName',
                    render: (text, record) => <Link to={`/customers/${record.customerId}`}>{text}</Link>,
                    filters: this.props.projects
                        .map(r => { return { text: r.customerName, value: r.customerName } })
                        .reduce((accum: ColumnFilterItem[], val: ColumnFilterItem) => accum.some(v => v.text === val.text) ? accum : [...accum, val], []),
                    filterSearch: true,
                    onFilter: (value, record) => {
                        return record.customerName === value
                    },
                    sorter: (a, b) => a.customerName.localeCompare(b.customerName),
                    width: '20%',
                },
            );

        this.setState({ columns })
    }


    render(): React.ReactElement<ProjectsList> {
        return (
            <div>
                <div className='flex flex-col gap-4'>
                    {this.props.isProjectsPage && <PartName name='Projects' />}

                    <Table
                        rowKey={(record) => record.id}
                        columns={this.state.columns}
                        dataSource={this.props.projects}
                        pagination={false}
                        showSorterTooltip={false}
                        size='small'
                    />
                </div>
            </div>
        );
    }
}

export default ProjectsList;