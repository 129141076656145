import * as React from 'react';

import RockettaModal from './RockettaModal';
import { DefaultButton, PrimaryButton } from '@fluentui/react';

interface EasyDeleteModalProps {
    isOpen: boolean
    closeModal: () => void
    deleteEntity: string
    onDeleteClick: () => void
}

interface EasyDeleteModalState {

}

class EasyDeleteModal extends React.Component<EasyDeleteModalProps, EasyDeleteModalState> {
    constructor(props: EasyDeleteModalProps) {
        super(props);
    }
    render(): React.ReactElement<EasyDeleteModal> {
        return (
            <RockettaModal
                closeModal={this.props.closeModal}
                isOpen={this.props.isOpen}
                name={'Delete ' + this.props.deleteEntity + '?'}
            >
                <div className='text-gray-600 w-96'>
                    Are you sure you want to delete {this.props.deleteEntity}?
                </div>

                <div className='flex float-right mt-7'>
                    <PrimaryButton
                        className='primary-button mr-4'
                        onClick={this.props.onDeleteClick}
                        text='Delete'
                    />
                    <DefaultButton
                        onClick={this.props.closeModal}
                        text='Cancel'
                    />
                </div>
            </RockettaModal>
        );
    }
}

export default EasyDeleteModal;